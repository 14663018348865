import React, { Component, useEffect, useState } from "react";

import Select from 'react-select'
import FontsPicker from '../email-editor/FontsPicker';
import { HexColorPicker } from "react-colorful";

import LoadingContext from "../ToggleContext";
import User from "../services/User";
import {ApiService} from "../services/ApiService";
import { Form } from 'react-bootstrap';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { colors } from "@mui/material";

const mSwal = withReactContent(Swal)

export class Presets extends Component {
  static contextType = LoadingContext

  constructor(props) {
    super(props)
    this.state = {
      account:{},
      presets:{},
      webfont:{},
      colorpickers:{
        background:false,
        containerColor:false
      },
      avail_fonts:[
          {value:'Arial', label:'Arial'},
          {value:'Helvetica', label:'Helvetica'},
          {value:'Times New Roman', label:'Times New Roman'},
          {value:'Courier New', label:'Courier New'},
          {value:'Georgia', label:'Georgia'},
          {value:'Trebuchet MS', label:'Trebuchet MS'},
          {value:'Verdana', label:'Verdana'},
          {value:'Tahoma', label:'Tahoma'},
          {value:'Palatino', label:'Palatino'},
          {value:'Impact', label:'Impact'}
      ]
    };
  }

  componentDidMount() {
    this.loadAccountDetails();
  }
  async loadAccountDetails(){
    let user = await User.get();
    let account = await ApiService.get('fairymailer/getAccount',user.jwt);
    console.log('account.data',account.data)
    let _fonts = this.state.avail_fonts;
    if(account.data.fonts){
      account.data.fonts.forEach(gfont => {
        console.log(gfont)
        _fonts.unshift({value:`'${gfont.data.family}', ${gfont.data.category}`, label: gfont.name})
      });
      this.setState({avail_fonts:_fonts})
    }
    const defaultPresets = account.data.presets ? account.data.presets : {
      fontSize:14,
      containerWidth:720,
      containerPadding:20,
      containerMargin:10,
      lineHeight:1.3,
      letterSpacing: 0
    }
    this.setState({account:account.data,presets:defaultPresets})
    this.context.setLoading_(false)
  }
  async savePresets(){
    console.log(this.state.presets)
    this.context.setLoading_(true)
    mSwal.fire({
      didOpen: async ()=>{
        Swal.showLoading();
        let resp = await ApiService.put(`accounts/${this.state.account.user.account.id}`,{data:{presets:this.state.presets}},User.get().jwt);
        console.log('save resp',resp)
        mSwal.fire({
          icon:'success',
          timer:1000
        }).then(()=>{
          this.context.setLoading_(false);
        })
      }
    })
  }
  toggleColorPicker(which){
    let prev = this.state.colorpickers;
    prev[which] = !prev[which];
    this.setState({colorpickers:prev})
  }
  updateInputValue(input,evt) {
    let st = this.state
    console.log(input,evt)
    if(input.includes(':')){
      let _input = input.split(':');
      if(!st[_input[0]]) st[_input[0]] = {};
      st[_input[0]][_input[1]] = evt
      this.setState(st);
    }
    // st[input] = evt.target.value;
  }
  async loadFontsPicker(){
    const _instance = this;
    mSwal.fire({
      icon:'info',
      willOpen: async()=>{
        Swal.showLoading();
        let fonts = await ApiService.get_external('https://www.googleapis.com/webfonts/v1/webfonts?key=AIzaSyAbwtvy5bTzyD0CSYS6QyGUnu6GsZXnZ5Y');
        const font_opts = fonts.data.items.map((font,i)=>{return {value:i,label:font.family}});
        mSwal.fire({
          customClass:`font-picker`,
          html:( <FontsPicker fontsRaw={fonts.data.items} font_opts={font_opts} onFontSelected={(vv)=>{_instance.setState({webfont:vv})}} />),
          preConfirm: () => {
            return fonts.data.items[_instance.state.webfont]
          }
        }).then(async res=>{
          console.log(res.value);
          let _fonts = _instance.state.avail_fonts;
          _fonts.unshift({value:`'${res.value.family}', ${res.value.category}`, label: res.value.family})
          _instance.setState({avail_fonts:_fonts})
          await ApiService.post('fairymailer/addWebFontToAccount',{fontName:res.value.family,fontData:res.value},User.get().jwt).then(res=>{
            console.log('post save web font',res);
          })
        })
      }
    })
  }
  render () {

    const { account } = this.state;
    const { loading, setLoading_ } = this.context;
    return (
      <div className='account-presets'>
        <div className="row">
          <div className="col-sm-12 col-md-6 grid-margin stretch-card">
            <div className="card">
                <div className="card-body">
                  <div className="d-flex align-items-center align-self-start">
                    <h3 className="mb-0">Colors & Containers</h3>
                  </div>
                  <br></br>
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="form-group">
                        <Form.Group>
                          <label htmlFor="backgroundColor">Background color:</label>
                          <div className="color-selected" style={{backgroundColor:this.state.presets.backgroundColor}} onClick={()=>{this.toggleColorPicker('background')}}></div>
                          {this.state.colorpickers.background ? (<HexColorPicker color={this.state.presets.backgroundColor} onChange={evt => this.updateInputValue('presets:backgroundColor',evt)} />) : ''}
                        </Form.Group>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <Form.Group>
                          <label htmlFor="containerColor">Container color:</label>
                          <div className="color-selected" style={{backgroundColor:this.state.presets.containerColor}} onClick={()=>{this.toggleColorPicker('containerColor')}}></div>
                          {this.state.colorpickers.containerColor ? (<HexColorPicker color={this.state.presets.containerColor} onChange={evt => this.updateInputValue('presets:containerColor',evt)} />) : ''}
                        </Form.Group>
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div className="form-group">
                        <Form.Group>
                          <label htmlFor="textColor">Text color:</label>
                          <div className="color-selected" style={{backgroundColor:this.state.presets.textColor}} onClick={()=>{this.toggleColorPicker('textColor')}}></div>
                          {this.state.colorpickers.textColor ? (<HexColorPicker color={this.state.presets.textColor} onChange={evt => this.updateInputValue('presets:textColor',evt)} />) : ''}
                        </Form.Group>
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div className="form-group">
                        <Form.Group>
                          <label htmlFor="containerWidth">Container width (px):</label>
                          <input type="number" min={'320'} max={'1024'} className="form-control form-control-lg" id="containerWidth" value={this.state.presets.containerWidth} onChange={evt => this.updateInputValue('presets:containerWidth',evt.target.value)}/>

                        </Form.Group>
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div className="form-group">
                        <Form.Group>
                          <label htmlFor="containerPadding">Container padding (px):</label>
                          <input type="number" min={'0'} max={'400'} className="form-control form-control-lg" id="containerPadding" value={this.state.presets.containerPadding} onChange={evt => this.updateInputValue('presets:containerPadding',evt.target.value)}/>
                        </Form.Group>
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div className="form-group">
                        <Form.Group>
                          <label htmlFor="containerMargin">Container margin (px):</label>
                          <input type="number" min={'0'} max={'400'} className="form-control form-control-lg" id="containerMargin" value={this.state.presets.containerMargin} onChange={evt => this.updateInputValue('presets:containerMargin',evt.target.value)}/>
                        </Form.Group>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-6 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                  <div className="d-flex align-items-center align-self-start">
                    <h3 className="mb-0">Font</h3>
                  </div>
                  <br></br>
                <div className="row">
                  
                <div class="col-sm-6">
                  <div className="form-group">
                    <Form.Group>
                      <label htmlFor="defaultFont">Default Font:</label>
                      <Select options={this.state.avail_fonts} styles={{
                          option: provided => ({
                            ...provided,
                            color: 'black'
                          }),
                          control: provided => ({
                            ...provided,
                            color: 'black'
                          }),
                          singleValue: provided => ({
                            ...provided,
                            color: 'black'
                          })
                          }} className="" id="defaultFont" value={this.state.presets.fontFamily} onChange={evt => this.updateInputValue('presets:fontFamily',evt)}/>   
                    </Form.Group>
                    <button className="btn btn-lg btn-primary" onClick={()=>{this.loadFontsPicker()}}>Load Google Fonts</button>
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="form-group">
                      <Form.Group>
                        <label htmlFor="fontSize">Font Size (px): </label>
                        <input type="number" min={'08'} max={'72'} className="form-control form-control-lg" id="fontSize" value={this.state.presets.fontSize} onChange={evt => this.updateInputValue('presets:fontSize',evt.target.value)}/>
                      </Form.Group>
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="form-group">
                      <Form.Group>
                        <label htmlFor="lineHeight">Line Height (em): </label>
                        <input type="number" min={'0'} max={'5'} className="form-control form-control-lg" id="lineHeight" value={this.state.presets.lineHeight} onChange={evt => this.updateInputValue('presets:lineHeight',evt.target.value)}/>
                      </Form.Group>
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="form-group">
                      <Form.Group>
                        <label htmlFor="letterSpacing">Letter Spacing (px): </label>
                        <input type="number" min={'0'} max={'50'} className="form-control form-control-lg" id="letterSpacing" value={this.state.presets.letterSpacing} onChange={evt => this.updateInputValue('presets:letterSpacing',evt.target.value)}/>
                      </Form.Group>
                  </div>
                </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row" >
          <div className="col-sm-12">
            <div className="btn btn-success btn-lg" style={{float:'right'}}  onClick={()=>{this.savePresets()}}>Save presets</div>
          </div>
        </div>
      </div> 
    );
  }
}

export default Presets;