import axios from 'axios';

// const BASE_URL = 'http://localhost:1337/api'; 
const BASE_URL = 'https://fairymail.cobaltfairy.com/api'; 

// const PIXEL_URL = 'https://mailer-demo.cobaltfairy.online/api'; 
const PIXEL_URL = 'https://fairymail.cobaltfairy.com/api'; 
const ApiService = {
  get_external: (endpoint) => {
    const url = `${endpoint}`;
    return axios.get(url);
  },
  post_external: (endpoint,data) => {
    const url = `${endpoint}`;
    return axios.post(url,data);
  },
  get: (endpoint,jwt) => {
    const url = `${BASE_URL}/${endpoint}`;
    return axios.get(url,{
      headers: {
        'Authorization': 'Bearer ' + jwt
      }
    });
  },
  post: (endpoint, data, jwt, headers=null) => {
    const url = `${BASE_URL}/${endpoint}`;
    return axios.post(url, data,{
      headers: headers ? headers : {
        'Authorization': 'Bearer ' + jwt
      }
    });
  },
  login: (credentials) => {
    credentials.identifier = credentials.username;
    const url = `${BASE_URL}/auth/local/`;
    console.log(url,credentials);
    return axios.post(url, credentials, {
        headers:{
            'Content-Type':'application/json'
        },
    });
  },
  put: (endpoint, data, jwt) => {
    const url = `${BASE_URL}/${endpoint}`;
    return axios.put(url, data,{
      headers: {
        'Authorization': 'Bearer ' + jwt
      }
    });
  },
  delete: (endpoint,jwt) => {
    const url = `${BASE_URL}/${endpoint}`;
    return axios.delete(url,{
      headers: {
        'Authorization': 'Bearer ' + jwt
      }
    });
  }
};


export { ApiService, BASE_URL, PIXEL_URL };