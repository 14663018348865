import React, { Component, useState } from 'react';
import { ApiService, BASE_URL, PIXEL_URL }  from '../services/ApiService';
import User  from '../services/User';
import { useParams } from 'react-router-dom';
import grapesjs from 'grapesjs';
import plugin from 'grapesjs-preset-newsletter';
import { v4 as uuidv4 } from 'uuid';
import LoadingContext from '../ToggleContext';
import FontsPicker from './FontsPicker';

import cf_footer_signature from './cf-footer-signature.component'
import cf_about_the_book from './cf-about-the-book.component'
import cf_unsubscribe_component from './cf-unsubscribe.component'
import cf_review_btn from'./cf-review-button.component'
import cf_main_container from'./cf-main-container.component'

import 'grapesjs/dist/css/grapes.min.css';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const mSwal = withReactContent(Swal)

function withParams(Component) {
  return props => <Component {...props} params={useParams()} />;
}

const MAIN_APP_BASE_URL = 'https://fairymail.cobaltfairy.com';

export class CFMailEditor extends Component {
  static contextType = LoadingContext;

  constructor(props) {
    super(props)
    this.state = {
      account:{},
      account_presets:{},
      editorType:'',
      uuid:'',
      template_return_aut_id:'',
      entity:null,
      additionalStyles:'',
      saved_html: '',
      finishedLoading:false,
      webfonts:{selected:'',sampletext:'Lorem ispum dolor sit amet.'},
      typography_sector: {
        open:false,
        name: 'Typography',
        properties: [
          {
            property: 'font-family',
            label: 'Font',
            type: 'select',
            default: `'Arial', 'Helvetica', sans-serif;`,
            options: [
              {value:'Arial', name:'Arial'},
              {value:'Helvetica', name:'Helvetica'},
              {value:'Times New Roman', name:'Times New Roman'},
              {value:'Courier New', name:'Courier New'},
              {value:'Georgia', name:'Georgia'},
              {value:'Trebuchet MS', name:'Trebuchet MS'},
              {value:'Verdana', name:'Verdana'},
              {value:'Tahoma', name:'Tahoma'},
              {value:'Palatino', name:'Palatino'},
              {value:'Impact', name:'Impact'},
            ]
          },
          {
            property:'font-weight',
            label:'Weight',
            type:'select',
            default:'normal',
            options:[
              {value:'200',label:'Light'},
              {value:'normal',label:'Normal'},
              {value:'bold',label:'Bold'},
            ]
          },
          {
            property:'font-size',
            label:'Font Size',
            type:'slider',
            default:'14px',
            units: ['px', 'pt'],
            min: 0, 
            max:100
          },
          {
            property:'line-height',
            label:'Line height',
            type:'slider',
            default:'1em',
            units: ['px', 'em'],
            min: 0, 
            step: 0.1,
            max: 100
          },
          {
            property:'letter-spacing',
            label:'Letter Spacing',
            type:'slider',
            default:'0',
            units: ['px', 'em'],
            min: 0, 
            step: 0.5,
            max: 100
          }
        ],
      }
    };
    this.webfontsRef = React.createRef();
  }
  
  
  async componentDidMount() {
    console.log(this.props.params)
    if(this.props.params.tplId){
      this.setState({
        editorType:'template',
        uuid:this.props.params.tplId
      })
    }
    
    if(this.props.params.autId){
      this.setState({
        template_return_aut_id:this.props.params.autId
      })
    }
    if(this.props.params.cmpId){
      this.setState({
        editorType:'campaign',
        uuid:this.props.params.cmpId
      })
    }
    let account = await ApiService.get(`fairymailer/getAccount`,User.get().jwt)
    let fonts = account.data.fonts;
    account = account.data.user.account;
    account.webfonts = fonts;
    let presets = {};
    let typography_sector = this.state.typography_sector;
    if(account.presets) presets = account.presets
    if(presets.fontFamily && presets.fontFamily.value){
      typography_sector.properties[0].default = presets.fontFamily.value
      console.log('Typography sector',typography_sector)
    }
    this.setState({account:account,account_presets:presets,typography_sector:typography_sector})
    console.log('this.props.params',this.props.params)
    console.log('user',User.get())
    
  }

  handleWebfontsOptionChange = (value) => {
    let wf = this.state.webfonts;
    wf.selected = value;
    this.setState({ webfonts:wf }, () => {
      this.webfontsRef.selected = value;
    });
  };
  handleWebfontsSampleTextChange = (value) => {
    let wf = this.state.webfonts;
    wf.sampletexet = value;
    this.setState({ webfonts:wf }, () => {
      this.webfontsRef.sampletext = value;
    });
  };
  
render() {   
  const user = User.get();  
  const account = this.state.account;
  console.log('account',account)
  const instance = this;
  const settingsPanel = (editor,component)=>{
      if(editor.StyleManager.getSector('decorations')) editor.StyleManager.removeSector('decorations')
      if(editor.StyleManager.getSector('typography')) editor.StyleManager.removeSector('typography')
      if(editor.StyleManager.getSector('dimension')) editor.StyleManager.removeSector('dimension')
      editor.StyleManager.addSector('dimensions',{
          open:false,
          name: 'Shape',
          properties: [
            { 
              type: 'select', 
              label:'Fill',
              property: 'display',
              default: 'block',
              options: [
                {value: 'flex', label: 'Inline'},{value: 'block', label: 'Box'}] 
            },
            { 
              type: 'select', 
              label:'Float',
              property: 'float',
              default: 'auto',
              options: [
                {value: 'auto', label: 'Auto'},{value: 'left', label: 'Left'},{value: 'right', label: 'Right'}] 
            },
            { 
              type: 'radio', 
              label:'Alignment',
              property: 'text-align',
              default: 'center',
              options: [
                {value: 'left', label: 'Left'},{value: 'center', label: 'Center'},{value: 'right', label: 'Right'},{value:'Justify',label:'justify'}] 
            },
            {
              type: 'slider',
              label: 'Width',
              property: 'width',
              default: 'auto',
              units: ['px','%'],
              min: 0, 
              max:1920
            },
            {
              type: 'slider',
              label: 'Max Width',
              property: 'max-width',
              default: '100%',
              units: ['px','%'],
              min: 0, 
              max:1920
            },
            {
              type: 'slider',
              label: 'Height',
              property: 'height',
              default: 'auto',
              units: ['px','%'],
              min: 0, 
              max:1920
            },
            {
              type: 'slider',
              label: 'Max Height',
              property: 'max-height',
              default: '100%',
              units: ['px','%'],
              min: 0, 
              max:1920
            },
            {
              type: 'slider',
              label: 'Radius',
              property: 'border-radius',
              default: '0',
              units: ['px','%'],
              min: 0, 
              max:100
            },
          ],
      })
      editor.StyleManager.addSector('spacing',{
          open:false,
          name: 'Spacing',
          properties: [
            {
              id: 'padding-top',
              type: 'slider',
              label: 'Top Padding',
              property: 'padding-top',
              default: '0',
              units: ['px', '%'],
              min: 0, 
              max:400
            },
            {
              id: 'padding-left',
              type: 'slider',
              label: 'Left Padding',
              property: 'padding-left',
              default: '0',
              units: ['px', '%'],
              min: 0, 
              max:400
            },
            {
              id: 'padding-bottom',
              type: 'slider',
              label: 'Bottom Padding',
              property: 'padding-bottom',
              default: '0',
              units: ['px', '%'],
              min: 0, 
              max:400
            },
            {
              id: 'padding-right',
              type: 'slider',
              label: 'Right Padding',
              property: 'padding-right',
              default: '0',
              units: ['px', '%'],
              min: 0, 
              max:400
            },
            {
              type: 'slider',
              label: 'Top Margin',
              property: 'margin-top',
              default: '0',
              units: ['px', '%'],
              min: 0, 
              max:400
            },
            {
              type: 'slider',
              label: 'Bottom Margin',
              property: 'margin-bottom',
              default: '0',
              units: ['px', '%'],
              min: 0, 
              max:400
            },
            {
              type: 'slider',
              label: 'Left Margin',
              property: 'margin-left',
              default: '0',
              units: ['px', '%'],
              min: 0, 
              max:400
            },
            {
              type: 'slider',
              label: 'Right Margin',
              property: 'margin-right',
              default: '0',
              units: ['px', '%'],
              min: 0, 
              max:400
            },
          ],
      })
      editor.StyleManager.addSector('color',
        {
          open:false,
          name: 'Color & Border',
          properties: [
            {
              property: 'background-color',
              label: 'Background',
              type: 'color',
              default: '#ffffff00',
            },
            {
              property: 'background-size',
              label: 'Background Size',
              type: 'select', 
              options: [{id: 'contain', label: 'Contain'},{id: 'cover', label: 'Cover'},{id: 'auto', label: 'Auto'},] 
            },
            {
              property: 'background-repeat',
              label: 'Background Repeat',
              type: 'select', 
              options: [{id: 'no-repeat', label: 'No Repeat'},{id: 'repeat', label: 'repeat'},{id: 'repeat-x', label: 'Repeat Horizontally'},{id:'repeat-y',label:'Repeat Vertically'}] 
            },
            {
              property: 'color',
              label: 'Text Color',
              type: 'color',
              default: '#000000',
            },
            {
              property: 'border-color',
              label: 'Border Color',
              type: 'color',
              default: '#000000',
            },
            { 
              type: 'select', 
              label:'Border style',
              property: 'border-style',
              default: 'solid',
              options: [{id: 'solid', label: 'Solid'},{id: 'dashed', label: 'Dash'},{id: 'dotted', label: 'Dots'},] 
            },
            {
              type: 'composite',
              property:'border',
              label: 'Border',
              properties: [
                { type: 'number', units: ['px'], default: '0', property: 'border-top' },
                { type: 'number', units: ['px'], default: '0', property: 'border-right' },
                { type: 'number', units: ['px'], default: '0', property: 'border-bottom' },
                { type: 'number', units: ['px'], default: '0', property: 'border-left' },
              ]
            }
          ],
      })
      if(['link','text','fairymail-text'].includes(component.attributes.type)){
        editor.StyleManager.addSector('typography', instance.state.typography_sector)
        console.log('typography_sector updated')
      }
      if(['text','image','fairymail-text'].includes(component.attributes.type)){
        // Add a custom sector with a link input
          // editor.StyleManager.addSector('Link', {
          //   name: 'Link',
          //   open: true,
          //   buildProps: ['link'],
          //   properties: [
          //     {
          //       property: 'link',
          //       type: 'link',
          //       name: 'Link',
          //       placeholder: 'https://',
          //       defaults: '',
          //       changeProp: 1,
          //     }
          //   ]
          // });
          if (!component.getTrait('link')) {
            component.addTrait({
              type: 'fairymail-link',
              name: 'Link',
              label: 'Link',
              placeholder: 'https://',
              changeProp: 1,
              value: component.get('link') || ''
            });
          }
      }
      //   editor.Blocks.add('link-block', {
      //     category: 'Basic',
      //     label: 'Link Block',
      //     attributes: {
      //         class: "fas fa-link"
      //     },
      //     content: {
      //         type: "link",
      //         content: "Insert your link here",
      //         style: {
      //             color: "#3B97E3"
      //         }
      //     }
      // });

      // editor.DomComponents.addType('link', {
      //   extend: 'link',
      //   isComponent: (el) => el.tagName === 'A',
      //   model: {
      //       defaults: {
      //           tagName: 'a',
      //           traits: [
      //               {
      //                   type: 'link',
      //               }
      //           ]
      //       },
      //       init() {},
      //   }
      // });
      // editor.TraitManager.addType('link', {
      //   noLabel: true,
      //   createInput({trait}) {
      //       const newTabCheckBox = generateCheckBoxField('new-tab', 'Open in new tab');
      //       const listElementsCheckBox = generateCheckBoxField('list-elements', 'List elements');
      //       const elementSelect = generateSelectField('element-id-select', 'Element ID', getElements());
      //       const pageSelect = generateSelectField('page', 'Page', getPages());
      //       if(document.querySelector("#fairymail-link-url")) return;
      //       const el = document.createElement('div');
      //       el.innerHTML = `
      //           <div class="tab-container gjs-one-bg gjs-two-color">
      //               <div class="tab-icons gjs-pn-buttons gjs-radio-items">
      //                   <div class="tab-icon"> 
      //                       <input type="radio" class="gjs-sm-radio" name="link-type" value="url" checked="">
      //                       <label class="tab-button gjs-pn-btn gjs-four-color" data-tab="url"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20"><path d="M16 6h-3v1.9h3a4.1 4.1 0 1 1 0 8.2h-3V18h3c3.31 0 6-2.69 6-6 0-3.32-2.69-6-6-6M3.9 12A4.1 4.1 0 0 1 8 7.9h3V6H8c-3.31 0-6 2.69-6 6s2.69 6 6 6h3v-1.9H8c-2.26 0-4.1-1.84-4.1-4.1M8 13h8v-2H8v2z"></path></svg></label>
      //                   </div>
      //               </div>
      //               <div class="tab-content">
      //                   <div class="tab-panel active" data-tab="url">
      //                       <div class="gjs-trt-trait">
      //                           <span class="gjs-label-wrp">URL</span>
      //                           <div class="gjs-field gjs-field-text">
      //                               <input name="url" id="fairymail-link-url" placeholder="URL here"/>
      //                           </div>
      //                       </div>
      //                       ${newTabCheckBox}
      //                   </div>
      //               </div>
      //           </div>
      //           <style>
      //               .tab-icons {
      //                   margin: 0 0 15px 0;
      //               }
      //               .gjs-sm-radio + label {
      //                   padding: 5px 10px;
      //                   border-color: transparent;
      //                   border-width: 2px;
      //                   transition: color 0.25s, border-color 0.25s;
      //                   border-bottom: 2px solid transparent;
      //               }
      //               .gjs-sm-radio:checked + label {
      //                   color: #d278c9;
      //                   border-color: #d278c9;
      //               }
      //               .tab-icon {
      //                   display: inline-block;
      //               }
      //               .tab-content {
      //                   padding: 0;
      //               }
      //               .tab-panel {
      //                   display: none;
      //               }
      //               .tab-panel.active {
      //                   display: block;
      //               }
      //           </style>
      //       `;
            
      //       return el;
      //   },
      //   onInit:()=>{
      //     alert()
      //   },
      //   onEvent({ elInput, component, event }) {
      //       // console.log(event,elInput,component)
      //       console.log(document.querySelector("#fairymail-link-url").value);
  
      //       const urlInput = elInput.querySelector('input[name="url"]');
      //       if (component) {
      //           let linkType = 'url';
      //           const attributes = component.getAttributes();
      //           if (attributes.href) {
      //               if (attributes.href.startsWith('tel:')) {
      //                   linkType = 'phone';
      //               } else if (attributes.href.startsWith('mailto:')) {
      //                   linkType = 'email';
      //               } else if (attributes.href.startsWith('#')) {
      //                   linkType = 'id';
      //               } else {
      //                   linkType = 'url';
      //               }
      //           }
  
      //       }
  
      //       const updateComponent = () => {
      //           const selectedType = elInput.querySelector('.tab-icons input:checked').value;
      //           const newAttributes = {};
  
      //           switch (selectedType) {
      //               case 'url':
      //                   newAttributes.href = urlInput.value;
      //                   console.log('urlInput.value',urlInput.value)
      //                   break;
      //               // case 'id':
      //               //     newAttributes.href = `#${elementIdInput.value}`;
      //               //     break;
      //               // case 'email':
      //               //     newAttributes.href = `mailto:${emailInput.value}?subject=${subjectInput.value}`;
      //               //     break;
      //               // case 'phone':
      //               //     newAttributes.href = `tel:${phoneInput.value}`;
      //               //     break;
      //               // case 'page':
      //               //     newAttributes.href = pageSelect.value;
      //               //     break;
      //           }
  
      //           // newAttributes.target = newTabCheckBox.checked ? '_blank' : '';
  
      //           component.addAttributes(newAttributes);
      //           console.log('component updated',component);
      //       };
  
      //       urlInput.addEventListener('input', updateComponent);
      //       // newTabCheckBox.addEventListener('change', updateComponent);
      //   }
      // });

      // Update default link component
	editor.DomComponents.addType('link', {
    model: {
      defaults: {
        traits: [
          {
            type: 'fairymail-link',
            name: 'href',
            label: 'Fairmail Link',
          },
        ]
      }
    }
  });

  editor.TraitManager.addType('fairymail-link', {
    noLabel: true, 

    // Return a simple HTML string or an HTML element
    createInput({ trait }) {
      // Here we can decide to use properties from the trait
      const traitOpts = trait.get('options') || [];
      const options = traitOpts.lenght ? traitOpts : [
        { id: 'url', name: 'URL' },
        { id: 'email', name: 'Email' },
      ];

      // Create a new element container add some content
      const el = document.createElement('div');
      el.innerHTML = `
      <select class="fairymail-link__type">
        ${options.map(opt => 
        	`<option value="${opt.id}">${opt.name}</option>`)
          .join('')}
      </select>
      <div class="fairymail-link__url-inputs">
        <input class="fairymail-link__url" placeholder="Insert URL and hit Enter"/>
      </div>
      <div class="fairymail-link__email-inputs">
        <input class="fairymail-link__email" placeholder="Insert email"/>
        <input class="fairymail-link__email-subject" placeholder="Insert subject"/>
      </div>
    `;

      // Let's make our content alive
      const inputsUrl = el.querySelector('.fairymail-link__url-inputs');
      const inputsEmail = el.querySelector('.fairymail-link__email-inputs');
      const inputType = el.querySelector('.fairymail-link__type');
      inputType.addEventListener('change', ev => {
        switch (ev.target.value) {
          case 'url':
            inputsUrl.style.display = '';
            inputsEmail.style.display = 'none';
            break;
          case 'email':
            inputsUrl.style.display = 'none';
            inputsEmail.style.display = '';
            break;
        }
      });

      return el;
    },

    onEvent({ elInput, component }) {
      const inputType = elInput.querySelector('.fairymail-link__type');
      let href = '';

      switch (inputType.value) {
        case 'url':
          const valUrl = elInput.querySelector('.fairymail-link__url').value;
          href = valUrl;
          break;
        case 'email':
          const valEmail = elInput.querySelector('.fairymail-link__email').value;
          const valSubj = elInput.querySelector('.fairymail-link__email-subject').value;
					href = `mailto:${valEmail}${valSubj ? `?subject=${valSubj}` : ''}`;
          break;
      }
      component.addAttributes({ href });
      if (component.parent()) {
        const parentComponent = component.parent();
        console.log('parentComponent',parentComponent)
        if (parentComponent.attributes && parentComponent.attributes.tagName && parentComponent.attributes.tagName=="a") {
          if(href && href.length>1){
            //update the link
            parentComponent.addAttributes({ href: href, target:'_blank' });
          }else{
            //remove the link
            parentComponent.replaceWith(component.clone());
          }
        } else {
          //add the link
          const linkComponent = editor.Components.addComponent({
            type: 'link',
            attributes: { href: href, target:'_blank' },
            components: [component.clone()],
          });
          component.replaceWith(linkComponent);
          editor.select(linkComponent);
        }
      } else {
        //add the link, in case there's no parent.
        const linkComponent = editor.Components.addComponent({
          type: 'link',
          attributes: { href: href, target:'_blank' },
          components: [component.clone()],
        });
        component.replaceWith(linkComponent);
        editor.select(linkComponent);
      }

    },

    onUpdate({ elInput, component }) {
      const href = component.getAttributes().href || '';
      const inputType = elInput.querySelector('.fairymail-link__type');
      let type = 'url';

      if (href.indexOf('mailto:') === 0) {
        const inputEmail = elInput.querySelector('.fairymail-link__email');
        const inputSubject = elInput.querySelector('.fairymail-link__email-subject');
        const mailTo = href.replace('mailto:', '').split('?');
        const email = mailTo[0];
        const params = (mailTo[1] || '').split('&').reduce((acc, item) => {
          const items = item.split('=');
          acc[items[0]] = items[1];
          return acc;
        }, {});
        type = 'email';

        inputEmail.value = email || '';
        inputSubject.value = params.subject || '';
      } else {
        elInput.querySelector('.fairymail-link__url').value = href;
      }

      inputType.value = type;
      inputType.dispatchEvent(new CustomEvent('change'));
    },
  }); 
  }

  
  if(!this.state.entity && this.state.uuid!="new" && !this.state.finishedLoading) 
    setTimeout( async ()=>{
      let blocks = [
          // {
          //   attributes: { class: 'fa fa-tablet' },
          //   id: 'cf-center-container',
          //   label: 'CF Center Container',
          //   content: `<div class="cf-center-container" style="min-height:150px;background:#FFF1D5;max-width:992px;margin: 0 auto;padding:2em;text-align:center;"></div>`,
          // },
          // {
          //   attributes: { class: 'fa fa-desktop' },
          //   id: 'cf-header-1',
          //   label: 'CF Header 1',
          //   content: cf_predef_header,
          // },
          // {
          //   label: 'Cobalt Fairy Logo',
          //   id:'cf-logo-image',
          //   attributes: { class: 'fa fa-image' },
          //   content: cflogoimg, 
          // },
          // {
          //   label: 'CF Pen Name Logo',
          //   id:'cf-penname-logo-image',
          //   attributes: { class: 'fa fa-image' },
          //   content: cf_precf_penname_logo, 
          // },
          // {
          //   label: 'CF Intro Template',
          //   id:'cf-text-template',
          //   attributes: { class: 'fa fa-align-justify' },
          //   content: cf_text_template, 
          // },
          // {
          //   attributes: { class: 'fa fa-window-minimize' },
          //   id: 'cf-divider',
          //   label: 'CF Divider',
          //   content: divider
          // },
          {
            attributes: { class: 'fa fa-align-justify' },
            id: 'cf-main-container',
            label: 'Main Container',
            content: cf_main_container
                                      .replace("{{CONTAINER_BACKGROUND}}",`${this.state.account_presets?.containerColor ? this.state.account_presets?.containerColor : 'inherit'}`)
                                      .replace("{{CONTAINER_PADDING}}",`${this.state.account_presets?.containerPadding ? this.state.account_presets?.containerPadding+'px' : '5px'}`)
                                      .replace("{{CONTAINER_WIDTH}}",`${this.state.account_presets?.containerWidth ? this.state.account_presets?.containerWidth+'px' : '640px'}`)
          },
          {
            attributes: { class: 'fa fa-book' },
            id: 'cf-two-cols',
            label: 'About the Book',
            content: cf_about_the_book
          },
          {
            attributes: { class: 'fa fa-address-card' },
            id: 'cf-account-details',
            label: 'Account Footer',
            content: cf_footer_signature.split('{{#ACCOUNT_EMAIL#}}').join(account.from_email ? account.from_email : '' ).split('{{#ACCOUNT_WEBSITE#}}').join(account.sending_identity ? account.sending_identity.domain : '').replace('{{#ACCOUNT_NAME#}}',account.name ? account.name : '')
          },
          {
            attributes: { class: 'fa fa-user-times' },
            id: 'cf-unsubscribe-component',
            label: 'Unsubscribe',
            content: cf_unsubscribe_component.replace('{{#UNSUBSCRIBE_LINK#}}',`${MAIN_APP_BASE_URL}/api/unsubscribe/{{pixel_uid}}/{{pixel_group}}`)
          },
          {
            attributes: { class: 'fa fa-star-half-o' },
            id: 'cf-review-btn',
            label: 'Review Button',
            content: cf_review_btn
          },
          // {
          //   attributes: { class: 'fa fa-amazon' },
          //   id: 'cf-read-button',
          //   label: 'CF Read Book Button',
          //   content: cf_read_book_button
          // },
      ];
      let customblocks = account.id ? await ApiService.get(`accounts/${account.id}?fields=custom_components`, user.jwt) : '';
      if(customblocks && customblocks.data.data.attributes.custom_components) customblocks  =customblocks.data.data.attributes.custom_components;
      customblocks = customblocks.length>0 ? customblocks.map(i=>{i.content=decodeURIComponent(atob(i.content));return i;}) : []
      blocks = blocks.concat(customblocks)
      const cdn_user = btoa(`${user.user.id}:${user.user.username}`);
      const editor = grapesjs.init({
        container : '#gjs',
        fromElement: true,
        storageManager: false,
        canvas: {
          styles: 
          (this.state.account.webfonts && this.state.account.webfonts.length>0) ? this.state.account.webfonts.map(font=>{
            return `<link href="https://fonts.googleapis.com/css2?family=${font.name.split(' ').join('+')}:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap" rel="stylesheet">`
          }) : []
        },
        assetManager: {
          storageType      : '',
          storeOnChange    : true,
          storeAfterUpload  : true,   
          upload: `https://cdn.cobaltfairy.online/controller.php?user=${cdn_user}&cmp=${btoa(this.props.params.cmpId)}`, 
        },
        content:'',
        plugins: [ plugin ],
        pluginsOpts: {
          "gjs-preset-newsletter": {
            inlineCss: false,
          },
        },
        blockManager: {
          blocks: blocks
        }
      });

      const domc = editor.DomComponents
      const class_instance = this;
      

      domc.addType('image-block', {
        extend: 'default',
        model: {
          defaults: function () {
            return {
              name: 'Background image',
              type: 'image-block',
              tagName: 'div',
              void: false,
              droppable: true
            }
          }
        },
        view: {
          init () {
            this.listenTo(this.model, 'active', this.onActive)
            this.listenTo(this.model, 'change:src', this.updateImage);
            let defaultBgColor = class_instance.state.account_presets?.backgroundColor ? class_instance.state.account_presets.backgroundColor : "#ffffff";
            const el = this.model.getEl();
            el.style.backgroundColor = defaultBgColor;
            this.model.addAttributes({ 'background-color': defaultBgColor });
            this.model.addAttributes({ 'padding-top': class_instance.state.account_presets.containerMargin ? class_instance.state.account_presets.containerMargin+'px' : 'inherit' });
            this.model.addAttributes({ 'padding-bottom': class_instance.state.account_presets.containerMargin ? class_instance.state.account_presets.containerMargin+'px' : 'inherit' });
            const style = this.model.getStyle() || {};
            style['background-color'] = defaultBgColor;
            style['padding-top'] = class_instance.state.account_presets.containerMargin ? class_instance.state.account_presets.containerMargin+'px' : 'inherit';
            style['padding-bottom'] = class_instance.state.account_presets.containerMargin ? class_instance.state.account_presets.containerMargin+'px' : 'inherit';
            this.model.setStyle(style);
        
            // Re-render the component to apply the changes
            this.model.view.render();
          }
          ,
          events: {
            dblclick: 'openImagePicker'
          },
          openImagePicker () {
            editor.runCommand('open-assets', {
              target: this.model,
              types: ['image'],
              accept: 'image/*'
            })
          },
          onActive () {
          },
          updateImage (model, url) {
            if (url) {
              const style = model.getStyle()
              model.setStyle({
                'background-image': style['background-color'] || `url("${url}")`,
                'background-size': style['background-size'] || 'contain',
                'background-position': style['background-position'] || 'center center',
                'background-repeat': style['background-repeat'] || 'repeat',
                'min-height': style['min-height'] || '100%'
              })
            }
          }
        }
      })
      domc.addType('fairymail-text', {
        model: {
          defaults: {
            tagName: 'div',
            style: {
              'font-family': (this.state.account_presets?.fontFamily) ? this.state.account_presets?.fontFamily?.value : 'sans-serif',
              'font-size': (this.state.account_presets?.fontSize) ? this.state.account_presets?.fontSize+'px' : '16px',
              'line-height': (this.state.account_presets?.lineHeight) ? this.state.account_presets?.lineHeight+'em' : 'inherit',
              'letter-spacing': (this.state.account_presets?.letterSpacing) ? this.state.account_presets?.letterSpacing+'px' : 'inherit',
              'color': (this.state.account_presets?.textColor) ? this.state.account_presets?.textColor : '#000000',
            },
            content: 'Insert your text here',
            traits: [],
          },
        },
      });

      const bm = editor.BlockManager

      bm.add('image-block', {
        label: 'Background Image',
        category: 'Basic',
        attributes: { class: 'fa fa-image' },
        content: {
          attributes: { class: 'fa fa-book' },
          type: 'image-block',
          activeOnRender: true,
          style: {
            'background-image': `url('${window.origin}/images/image-placeholder.png')`,
            'min-height': '50px',
            'height': 'auto',
            'background-size': 'cover',
            'background-position': 'center center',
            'background-repeat': 'no-repeat'
          }
        }
      })
      bm.remove('text');
      bm.remove('link');
      bm.remove('link-block');
      bm.add('fairymail-text-block', {
        label: 'Text Block',
        media: `<svg viewBox="0 0 24 24">
                  <path fill="currentColor" d="M18.5,4L19.66,8.35L18.7,8.61C18.25,7.74 17.79,6.87 17.26,6.43C16.73,6 16.11,6 15.5,6H13V16.5C13,17 13,17.5 13.33,17.75C13.67,18 14.33,18 15,18V19H9V18C9.67,18 10.33,18 10.67,17.75C11,17.5 11,17 11,16.5V6H8.5C7.89,6 7.27,6 6.74,6.43C6.21,6.87 5.75,7.74 5.3,8.61L4.34,8.35L5.5,4H18.5Z"></path>
                </svg>`,
        content: {
          type: 'fairymail-text',
          content: 'This is a text block with default font',
        },
        category: 'Basic', // Specify the category
        order: 1, // Lower value to place higher, adjust as needed
      });
      editor.setComponents([])
      editor.setStyle(``)

      if(this.state.editorType=="campaign"){
        let campaign_data = await ApiService.get(`fairymailer/getCampaigns/?filters[uuid]=${this.state.uuid}`,user.jwt);
        console.log(`campaigns?filters[uuid]=${this.props.params.cmpId}`,campaign_data)
        if(campaign_data.data && campaign_data.data.data) campaign_data = campaign_data.data.data[0]; else campaign_data = null;
        const campaign = campaign_data;
        this.setState({entity:campaign})
        console.log('cmpdata',campaign)
        let campaign_data_design;
        if(campaign.html && campaign.html.length>0){
          setTimeout(()=>{
            this.setState({additionalStyles:campaign.html})
            document.querySelector(".gjs-frame").contentWindow.document.querySelector("head").innerHTML += `${campaign.html}`
          },1000)
        }
        if(this.props.params.bodyType && this.props.params.bodyType=='body-b'){
          if(null==campaign_data.design_b && null!=campaign_data.design){
            mSwal.fire({icon:'question',text:'Do you wish to copy the email body A and then edit it as version B?',showCancelButton:true,showConfirmButton:true,cancelButtonText:'No, use blank canvas',confirmButtonText:'Yes, copy version A'}).then(res=>{
              if(res.value){
                campaign_data_design = JSON.parse(campaign_data.design);
                if(campaign_data_design && campaign_data_design.components && campaign_data_design.style){
                  editor.setComponents(campaign_data_design.components)
                  editor.setStyle(campaign_data_design.style)
                }
              }
            })
          }
        }
        if(campaign_data) campaign_data_design = JSON.parse( (!this.props.params.bodyType || 'body-b'!=this.props.params.bodyType) ? campaign_data.design : campaign_data.design_b);
        if(campaign_data_design && campaign_data_design.components && campaign_data_design.style){
          console.log('campaign_data',campaign_data)
          editor.setComponents(campaign_data_design.components)
          editor.setStyle(campaign_data_design.style)
        }
      }else{
        if(this.state.uuid!="new"){
          let tplData = await ApiService.get(`fairymailer/getTemplates/?filters[uuid]=${this.state.uuid}`,user.jwt);
          tplData = tplData.data.data[0];
          this.setState({entity:tplData})
          console.log('template',tplData)
          let design = tplData.design;
          design = JSON.parse(design)
          if(design && design.components && design.style){
            editor.setComponents(design.components)
            editor.setStyle(design.style)
          }
        }
      }

      
    editor.on('component:selected', (component) => {
      settingsPanel(editor,component);
      //save element icon
      setTimeout(()=>{
        window.gjs_selected = component;
        let toolbar = editor.editor.view.$el[0].querySelector('.gjs-toolbar-items');
        if(toolbar){
          var newElement = document.createElement("div");
          newElement.classList.add("gjs-toolbar-item", "floppy-save");
          newElement.onclick = function() {
            window.saveElement();
          };
          var iconElement = document.createElement("i");
          iconElement.classList.add("fa", "fa-floppy-o");
          newElement.appendChild(iconElement);
          toolbar.prepend(newElement)
        }
      },100)
    });
    editor.on('component:update:traits', (model) => {
      const linkTrait = model.getTrait('link');
      if (linkTrait) {
        const linkValue = linkTrait.get('value');
        if (linkValue) {
          const component = model;
          const parent = component.parent();
  
          // Check if the component is already wrapped with <a>
          if (component.parent().is('a')) {
            component.parent().addAttributes({ href: linkValue });
            console.log('parent is a',parent);
          } else {
            // Wrap the component with an <a> tag
            console.log( 'wrap with a ')
            const linkWrapper = editor.DomComponents.addComponent({
              type: 'link',
              components: [component.clone()],
              attributes: { href: linkValue }
            }, { at: parent.indexOf(component) });
            console.log('linkWraooer',linkWrapper)
            parent.removeChild(component);
            console.log('parent',parent)
          }
        }else{
          console.log('not link value',model.getTrait('link'))
        }
      }else{
        // console.log('not link trait',model)
      }
    });
    
    window.copyToClipboard = (text) => {
      const input = document.createElement('textarea');
      input.value = text;
      document.body.appendChild(input);
      input.select();
      document.execCommand('copy');
      document.body.removeChild(input);
      Swal.close()
    }
    window.saveElement = async ()=>{
      let html = window.gjs_selected.view.$el[0].innerHTML;
      let css = window.gjs.getSelected().getStyle();
      let stylestr = ``;
      Object.keys(css).forEach(item=>{
        stylestr+=`${item}:${css[item]};`
      })
      html = `<div style="${stylestr}">${html}</div>`
      console.log('in save element',html)
      if(html && html.length>0){
        mSwal.fire(
          {
            title: "Type a name for this element:",
            input: "text",
            inputAttributes: {
              autocapitalize: "off"
            },
            showCancelButton: true,
            confirmButtonText: "Save",
            showLoaderOnConfirm: true,
            preConfirm: async (input) => {
              try {
                let user = JSON.parse(decodeURIComponent(localStorage.getItem("cfmmuser")))
                let component = {
                  attributes: { class: 'fa fa-th-large' },
                  id: 'cf-custom-'+btoa(new Date().getTime()),
                  label: input,
                  content: btoa(encodeURIComponent(html)),
                }
                fetch(`${BASE_URL}/accounts/${account.id}`, {
                  method: 'GET',
                  headers: new Headers({ 'Authorization': `Bearer ${user.jwt}`})
                  // body: JSON.stringify(requestData) 
                })
                .then(response => {
                  if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                  }
                  return response.json(); // or response.text() depending on the expected response format
                }).then(data => {
                  if(!data || !data.data || !data.data.attributes){alert('Failed to save new element.');return;}
                  if(!data.data.attributes.custom_components) data.data.attributes.custom_components=[];
                  // component.label += data.data.attributes.custom_components.length+1;
                  data.data.attributes.custom_components.push(component)
                  fetch(`${BASE_URL}/accounts/${account.id}`, {
                    method: 'PUT',
                    headers: new Headers({ 'Authorization': `Bearer ${user.jwt}`, 'Content-type':'application/json'}),
                    body: JSON.stringify({data:{custom_components:data.data.attributes.custom_components}})
                  }) .then(response => {
                    if (!response.ok) {
                      throw new Error(`HTTP error! Status: ${response.status}`);
                    }
                    return response.json(); // or response.text() depending on the expected response format
                  })
                  .then(data => {
        
                    console.log('after save response.',data);
                    component.content = decodeURIComponent(atob(component.content))
                    window.gjs.BlockManager.blocks.push(component)
                    mSwal.fire({icon:'success',text:'Element saved!',timer:900});
                  });
                })
                .catch(error => {
                  // Handle any errors that occurred during the fetch
                  console.error('Fetch error:', error);
                });
              } catch (error) {
                Swal.showValidationMessage(`
                  Request failed: ${error}
                `);
              }
            },
            allowOutsideClick: false
          }).then((result) => {
            // console.log('result',result)
            // mSwal.fire({icon:"success",timer:1200,text:`Element saved!`});
            // if(result.value && result.value.data.data && result.value.data.data.id){
            // }
          }
          )
        
        

      }
    }
    let customButtons = [];
    if(this.state.template_return_aut_id){
      
      customButtons = [
        {
          id: 'save-tpl-return-automation',
          className: 'btn btn-success fa fa-floppy-o icon-blank',
          command: 'save-tpl-return-automation',
          attributes: {title: 'Save your template As'}
        }
      ]
    }else{
      customButtons = [
        {
          id: 'save-db',
          className: 'btn btn-success fa fa-floppy-o icon-blank',
          command: 'save-template',
          // content: 'Save',
          attributes: {title: 'Save Campaign Body'}
        },
        {
          id: 'tag-name',
          className: 'fa fa-code icon-blank',
          command: 'tag-name',
          attributes: {title: 'Add Placeholder'}
        },
        {
          id: 'save-tpl',
          className: 'fa fa-cloud-upload',
          command: 'save-tpl',
          attributes: {title: 'Save as template'}
        },
        {
          id: 'import-tpl',
          className: 'fa fa-cloud-download',
          command: 'import-tpl',
          attributes: {title: 'Import template'}
        },
        {
          id: 'add-webfonts',
          className: 'fa fa-font',
          command: 'add-webfonts',
          attributes: {title: 'Load Google Fonts'}
        }
      ]
    }

    const customPanel = editor.Panels.addPanel({
      id: 'custom-panel',
      after: 'devices-c',
      buttons: customButtons,
    });

    editor.Commands.add('add-webfonts', {
        run: async function(editor, sender)
        {
          mSwal.fire({
            icon:'info',
            willOpen: async()=>{
              Swal.showLoading();
              let fonts = await ApiService.get_external('https://www.googleapis.com/webfonts/v1/webfonts?key=AIzaSyAbwtvy5bTzyD0CSYS6QyGUnu6GsZXnZ5Y');
              console.log('googlefonts',fonts);
              const font_opts = fonts.data.items.map((font,i)=>{return {value:i,label:font.family}});
              mSwal.fire({
                customClass:`font-picker`,
                html:( <FontsPicker fontsRaw={fonts.data.items} font_opts={font_opts} onFontSelected={(vv)=>{let wf = instance.state.webfonts;wf.selected = vv; instance.setState({webfonts:wf})}} />),
                preConfirm: () => {
                  return fonts.data.items[instance.state.webfonts.selected]
                }
              }).then(async res=>{
                console.log(res.value);
                await ApiService.post('fairymailer/addWebFontToAccount',{fontName:res.value.family,fontData:res.value},User.get().jwt).then(res=>{
                  console.log('post save web font',res);
                })
                let existing = instance.state.typography_sector;
                existing.properties[0].options = [ {value: `'${res.value.family}', '${res.value.category}';`, name:res.value.family }, ...existing.properties[0].options ]
                existing.open=true;
                instance.setState({typography_sector:existing})
                editor.StyleManager.removeSector('typography')
              setTimeout(()=>{
                  // const styleTag = ` @import url('https://fonts.googleapis.com/css2?family=${res.value.family.toString().split(' ').join('+')}:wght@400;700&display=swap') `;
                  const styleTag = `<link href="https://fonts.googleapis.com/css2?family=${res.value.family.toString().split(' ').join('+')}:ital,wght@0,400;0,700;1,400;1,700&display=swap" rel="stylesheet">`;
                  document.querySelector(".gjs-frame").contentWindow.document.querySelector("head").innerHTML += `<style>${styleTag}</style>`
                  let oldExtraStyles = instance.state.additionalStyles;
                  oldExtraStyles += `${decodeURIComponent(styleTag)}`;
                  instance.setState({additionalStyles:oldExtraStyles})
                editor.StyleManager.addSector('typography', existing)
                console.log(existing)
                console.log(editor)
              },1)
              })
            }
          })
        }
    });
    if(!this.state.template_return_aut_id){
      editor.Commands.add('save-template', {
          run: async function(editor, sender)
          {
            sender && sender.set('active'); // turn off the button
            let components = editor.getComponents();
            let style = editor.getStyle()
            let templateData = { 
                components: components,
                style: style
            };
            localStorage.setItem('last-gjs-tpl', JSON.stringify(templateData));
            console.log(JSON.parse(JSON.stringify(templateData))) 
            let resp = await saveTemplate(templateData);
          }
      });
      editor.Commands.add('tag-name', {
          run: async function(editor, sender)
          {
            sender && sender.set('active'); // turn off the button
            let components = editor.getComponents();
            mSwal.fire({iconHtml:'',title:'Select a placeholder',text:'',html:`<p>Click on the tag you want, to copy it to your clipboard.</p><br><br><div class="tag-chips"><span class="tag-chip" onclick="window.copyToClipboard(this.innerText)">{{name}}</span><span class="tag-chip" onclick="window.copyToClipboard(this.innerText)">{{email}}</span><span class="tag-chip" onclick="window.copyToClipboard(this.innerText)">{{demo_var}}</span></div>`,showConfirmButton:false})
          }
      });
      editor.Commands.add('import-tpl',{
          run: async function(editor, sender)
          {
            sender && sender.set('active'); // turn off the button
            mSwal.fire({
              icon:'info',
              text:'Fetching templates...',
              didOpen: async () => {
                instance.context.setLoading_(true);
                Swal.showLoading();
                let templates = await ApiService.get(`fairymailer/getTemplates/?pagination[pageSize]=100&sort[createdAt]=desc`,await User.get().jwt);
                console.log('templates received',templates.data.data)
                mSwal.fire({icon:'success',html: `
                  <select id="swal-select" class="swal2-select">
                      ${templates.data.data.map(tpl=>{return `<option value="${tpl.uuid}">${tpl.name}</option>`})}
                  </select>
              `,focusConfirm: false,
              preConfirm: () => {
                  const selectedOption = Swal.getPopup().querySelector('#swal-select').value;
                  return selectedOption;
              }}).then(async res=>{
                if(res && res.value){
                  mSwal.fire({
                    icon:'info',
                    text:'Loading data...',
                    didOpen: async () => {
                      Swal.showLoading();
                      let tpl = await ApiService.get(`fairymailer/getTemplates/?filters[uuid]=${res.value}`,await User.get().jwt);
                      console.log('template',tpl)
                      tpl = tpl.data.data[0]
                      let design = tpl.design;
                      design = JSON.parse(design)
                      if(design && design.components && design.style){
                        editor.setComponents(design.components)
                        editor.setStyle(design.style)
                      }
                      instance.context.setLoading_(false);
                      mSwal.fire({icon:'success',timer:500})
                    }
                  });
                  //
                }

              })
              
                
              }
            })
          }
        })
        editor.Commands.add('save-tpl', {
          run: async function(editor, sender)
          {
            sender && sender.set('active'); // turn off the button
            mSwal.fire(
              {
                title: "Save template as:",
                input: "text",
                inputAttributes: {
                  autocapitalize: "off"
                },
                showCancelButton: true,
                confirmButtonText: "Save",
                showLoaderOnConfirm: true,
                preConfirm: async (input) => {
                  try {
                    let components = editor.getComponents();
                    let style = editor.getStyle()
                    let templateData = { 
                      components: components,
                      style: style
                    };
                    let resp = await saveAsNewTemplate(templateData,`<html><head>${editor.getStyle()}</head><body>${editor.getHtml()}</body></html>`,input);
                    return resp
                  } catch (error) {
                    Swal.showValidationMessage(`
                      Request failed: ${error}
                    `);
                  }
                },
                allowOutsideClick: false
              }).then((result) => {
                if(result.value && result.value.data.data && result.value.data.data.id){
                  mSwal.fire({icon:"success",timer:1200,text:`Template "${result.value.data.data.name}" saved!`});
                }
                console.log('result',result)
              }
              )
          }
      });
    }else{
      const automationUUIDredir = this.state.template_return_aut_id;
      editor.Commands.add('save-tpl-return-automation', {
        run: async function(editor, sender)
        {
          sender && sender.set('active'); // turn off the button
          mSwal.fire(
            {
              title: "Type a name for your template:",
              input: "text",
              inputAttributes: {
                autocapitalize: "off"
              },
              showCancelButton: true,
              confirmButtonText: "Save",
              showLoaderOnConfirm: true,
              preConfirm: async (input) => {
                try {
                  let components = editor.getComponents();
                  let style = editor.getStyle()
                  let templateData = { 
                    components: components,
                    style: style
                  };
                  let resp = await saveAsNewTemplate(templateData,`<html><head>${editor.getStyle()}</head><body>${editor.getHtml()}</body></html>`,input);
                  return resp
                } catch (error) {
                  Swal.showValidationMessage(`
                    Request failed: ${error}
                  `);
                }
              },
              allowOutsideClick: false
            }).then((result) => {
              if(result.value && result.value.data.data && result.value.data.data.id){
                mSwal.fire({icon:"success",timer:1200,text:`Template saved!`}).then(()=>{
                  window.location.href='/automations/flow/'+automationUUIDredir
                });
              }
              console.log('result',result)
            }
            )
        }
    });
    }
  
    
    editor.on('storage:load', function(e) { console.log('Loaded ', e);});
    editor.loadData()

    if(this.state.account.webfonts && this.state.account.webfonts.length>0){
      let existing = instance.state.typography_sector;
      if(!existing.loaded_account_fonts){
        this.state.account.webfonts.map(font=>{
          existing.properties[0].options = [ {value: `'${font.name}', 'Arial', 'Helvetica', sans-serif;`, name:font.name }, ...existing.properties[0].options ]
        })
        existing.open=true;
        existing.loaded_account_fonts=true;
        instance.setState({typography_sector:existing})
        editor.StyleManager.removeSector('typography')
      }
    }

    const saveAsNewTemplate = async (templateData,html,name) =>{
      return await ApiService.post(`templates`, {data:{name:name,design:JSON.stringify(templateData),uuid:uuidv4(),html:html,account:account.id}}, user.jwt);
    }
    const extractUrls = (htmlText) => {
      const urlRegex = /(?:https?:\/\/)?(?:www\.)?[\w.-]+(?:\.[a-z]{2,})+(?:\/[\w@?^=%&/~+#{}*-]*)*/gi;
      const matches = htmlText.match(urlRegex);
      // Remove duplicates using a Set
      return [...new Set(matches)];
  }
    const saveTemplate = async (templateData) =>{
      var allFonts = '';/*`
            @import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200..700&display=swap');
            @import url('https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');
            @import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap');
            @import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
            
      `*/
      if(this.state.account.webfonts && this.state.account.webfonts.length>0 ){
        this.state.account.webfonts.map(font=>{
          // allFonts+= `@import url('https://fonts.googleapis.com/css2?family=${font.name.split(' ').join('+')}:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');`
          allFonts+= `<link href="https://fonts.googleapis.com/css2?family=${font.name.split(' ').join('+')}:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap" rel="stylesheet">`
        })
        console.log('allFonts',allFonts)
      }
      const requiredStyles= `
        ${decodeURIComponent(allFonts)}
        <style>
            body, *{
              font-family:Arial
            }
            .button{padding: 10px 25px;background-color:#333;border: 1ox solid #333;border-radius:10px;color:white}
            a{  text-decoration-line: none!important; }
            #cf-two-cols img{ margin-right:20px;}

            @media(max-width:590px){
              div{
                float: auto!important;
              }
              div > img {
                max-width: 100%!important;
                float:auto!important;
              }
            }

        </style>
        ${instance.state.additionalStyles}

      `;
      console.log('requiredStyles',requiredStyles)
      mSwal.fire({
        icon:'info',
        text:'Please wait...',
        didOpen: async () => {
          this.context.setLoading_(true);
          Swal.showLoading();
          let data_to_save = {design:JSON.stringify(templateData),html:instance.state.additionalStyles};
          if(this.props.params.bodyType && this.props.params.bodyType=='body-b'){
            data_to_save = {design_b:JSON.stringify(templateData)}
          }
          let resp = await ApiService.put(`${this.state.editorType=="campaign" ? 'campaigns' : 'templates'}/${this.state.entity.id}`, {data:data_to_save}, await User.get().jwt);
          let pixel = `${PIXEL_URL}/custom/pixel.gif?${(this.state.editorType=="campaign" ? 'cid' : 'tid')}=${this.state.uuid}&uid={{pixel_uid}}&v={{cmp_version}}`;
          let html = editor.runCommand('gjs-get-inlined-html')
          // const regex = /https?:\/\/(\S*)/gm; // regex to find links
          // let links = regex.exec(html)
          let links = extractUrls(html)
          console.log(links);
          if(links) for(let l=0;l<links.length;l++){
            // console.log('link',links[l]);
            if(links[l].includes('fairymail.cobaltfairy.com') || links[l].includes('cdn.cobaltfairy.online')){
              // console.log('LINK REPLACE SKIP',links[l]);
              continue;
            }
            if(links[l].includes(' ')) links[l] = links[l].split(' ')[0];
            if(!links[l].includes('.jpg') && !links[l].includes('.jpeg') && !links[l].includes('.png') && !links[l].includes('.gif') && !links[l].includes('.webp') && !links[l].includes('.svg') && !links[l].includes('cdn.cobaltfairy.online')){
              if(links[l].includes('"')) links[l] = links[l].split('"')[0];
              if(!links[l].startsWith('http')) links[l] = `http://${links[l]}`;
              html = html.split(links[l]).join(`${PIXEL_URL}/custom/redir?${(this.state.editorType=="campaign" ? 'cid' : 'tid')}=${this.state.uuid}&uid={{pixel_uid}}&v={{cmp_version}}&r=${encodeURIComponent(links[l])}`);
            }
          }
          try{
            console.log('Added pixel: ',pixel);
            let sesresp = await ApiService.post('custom/assignSES',{uuid:this.state.entity.uuid,subject:this.state.entity.subject ? this.state.entity.subject : this.state.entity.name,design:`<html><head>${requiredStyles}</head>${html}<img src=${pixel}/></html>`},user.jwt);
            console.log('sesresp',sesresp,this.state.entity.uuid);
            if(this.state.editorType=="campaign" || this.state.entity.type=="absplit"){
              if((!this.state.entity.uuid_b || this.state.entity.uuid_b.length<5)){
                let entity = this.state.entity;
                entity.uuid_b = uuidv4();
                this.setState({entity:entity});
                let assignuuidb = await ApiService.put(`campaigns/${this.state.entity.id}`, {data:{uuid_b:entity.uuid_b}}, User.get().jwt);
                console.log('assignuuidb ',assignuuidb.data );
              }
              if(!this.state.entity.design_b){
                //only one body.
                let sesresp = await ApiService.post('custom/assignSES',{uuid:this.state.entity.uuid_b,subject:this.state.entity.subject_b,design:`<html><head>${requiredStyles}</head>${html}<img src=${pixel}/></html>`},user.jwt);
                console.log('sesresp B version',sesresp,this.state.entity.uuid_b);
              }
            }
            if(resp && resp.data && resp.data.data && resp.data.data.id && resp.data.data.id==this.state.entity.id){
              mSwal.fire({icon:'success',timer:800});
              this.context.setLoading_(false);
            }else{
              this.context.setLoading_(false);
              mSwal.fire({icon:'warning',title:'Campaign save failed :(',text:'The app faced a problem saving your changes. Please try again. If the problem persists please contact the support team.'})
            }
          }catch(error){
            console.log(error);
            this.context.setLoading_(false);
            mSwal.fire({icon:'error',text:'Failed to save campaign body. If this error persists please contact our support team.'})
          }
        
          
        }
      })
      
    }

    
    if(!this.state.finishedLoading){
      let accountfonts = ''
      if(this.state.account.webfonts && this.state.account.webfonts.length>0){
        this.state.account.webfonts.map(font=>{
          console.log('adding font',font.name)
          // accountfonts+= `@import url('https://fonts.googleapis.com/css2?family=${font.name.split(' ').join('+')}:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');`
          accountfonts+= `<link href="https://fonts.googleapis.com/css2?family=${font.name.split(' ').join('+')}:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap" rel="stylesheet">`

        })
        console.log('accountfonts',accountfonts)
        
      }

      document.querySelector(".gjs-frame").contentWindow.document.querySelector("head").innerHTML += `
        ${ accountfonts }
          <style>
            body, *{
              font-family:'Playfair Display', arial
            }
            .button{padding: 10px 25px;background-color:#333;border: 1ox solid #333;border-radius:10px;color:white}
            a{  text-decoration-line: none!important; }
            #cf-two-cols img{ margin-right:20px;}
            @media(max-width:590px){
              div{
                float: auto!important;
              }
              div > img {
                max-width: 100%!important;
                float:auto!important;
              }
            }
          </style>
        `;
      this.setState({finishedLoading:true})
      this.context.setLoading_(false);
    }

    window.gjs = editor;

  },1000)
  return (
    <div>
      <div id="gjs" style={{minHeight:'98vh'}}>
      </div>
    </div>
  )
}

}

export default withParams(CFMailEditor)
