import React, { Component, useEffect, useState } from "react";
import User from "../services/User";
import {ApiService} from "../services/ApiService";
import { Form } from 'react-bootstrap';
import { useParams, withRouter } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2'
import LoadingContext from "../ToggleContext";
const mSwal = withReactContent(Swal)
function withParams(Component) {
  return props => <Component {...props} params={useParams()} />;
}

export class ChangeAccountPassword extends Component {
  static contextType = LoadingContext;
  constructor(props) {
    super(props)
   this.reset()
   this.componentDidMount()
  }
  reset() {
    this.state = {
      oldpassword:'',
      password:'',
      password2:'',
    };
  }

  componentDidMount() {
   
  }
  async savePass(){
    if(!this.state.oldpassword || this.state.oldpassword.length<6){
      mSwal.fire({
        icon:'error',
        text:'Please type in your old password.'
      });
      return
    }
    if(!this.state.password || this.state.password.length<6){
      mSwal.fire({
        icon:'error',
        text:'Please type a new password.'
      });
      return
    }
    if(!this.state.password2 || this.state.password2.length<6){
      mSwal.fire({
        icon:'error',
        text:'Please repeat the same password.'
      });
      return
    }
    if(this.state.password2!=this.state.password){
      mSwal.fire({
        icon:'error',
        text:'New passwords do not match.'
      });
      return
    }
    this.context.setLoading_(true);
    let user = await User.get();
    let resp = await ApiService.post(`fairymailer/change-user-password-by-old-password`,{oldpassword:this.state.oldpassword,password:this.state.password,password2:this.state.password2},user.jwt);
    if(resp && resp.data && resp.data.code==200){
      mSwal.fire({
        icon:'success',
        text:'Password changed successfully!',
        timer:4000
      }).then(()=>{
        window.location.href='/dashboard'
      })
    }else{
      mSwal.fire({
        icon:'error',
        text:'Failed to change password: '+resp.data.message
      })
    }
    this.context.setLoading_(false);
  }
  render() {
    this.context.setLoading_(false);
    const user = User.get();
    const {oldpassword,password,password2} = this.state;
    return (
      <div className="campaign-editor-container automations-editor"> 
        <div className="row">
          <div className="col-sm-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <div className="card-title">
                  { user ? (<h2 className="">Change Password</h2>) : (<h2 className=""></h2>) }
                </div>
                <div className="form-group">
                  <div className="row">
                    <div className="col-xs-12 col-md-12 form-group">
                      <Form.Group>
                        <label htmlFor="campaignTitle">Old Password</label>
                        <input type="password" name="oldpassword" className="form-control form-control-lg" value={oldpassword} onChange={evt => {;this.setState({oldpassword:evt.target.value})}}/>
                      </Form.Group>
                    </div>
                    <div className="col-xs-12 col-md-12 form-group">
                      <Form.Group>
                        <label htmlFor="campaignTitle">New Password</label>
                        <input type="password" name="password" className="form-control form-control-lg" value={password} onChange={evt => {;this.setState({password:evt.target.value})}}/>
                      </Form.Group>
                    </div>
                    <div className="col-xs-12 col-md-12 form-group">
                      <Form.Group>
                        <label htmlFor="campaignTitle">Repeat Password</label>
                        <input type="password" name="password2" className="form-control form-control-lg" value={password2} onChange={evt => {;this.setState({password2:evt.target.value})}}/>
                      </Form.Group>
                    </div>
                  
                  </div>
                

                  <div className="campaign-buttons">
                        <button className="btn btn-lg btn-success" onClick={()=>{this.savePass()}}>Save new password</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withParams(ChangeAccountPassword);
