const cf_review_btn = `
<div style="margin:0 auto 10px auto;padding:5px 5px 5px 5px;background-color:#ffffff00;color:#000000;border-color:#000000;width:100%;">
    <tbody data-gjs-highlightable="true" id="i67hw" data-gjs-type="tbody" draggable="true" class="">
        <tr data-gjs-highlightable="true" id="ikrpt" data-gjs-type="row" draggable="true" class="">
            <td data-gjs-highlightable="true" id="ivweh" data-gjs-type="cell" draggable="true" class="">
                <a data-gjs-highlightable="true" id="ib91k" data-gjs-type="link" draggable="true" class="button">
                    <b id="in274" data-gjs-type="text" draggable="true">
                        Click to leave your Review!
                    </b>
                </a>
            </td>
        </tr>
    </tbody>
</div>`;
export default cf_review_btn;