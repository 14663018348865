import React, { Component, useRef, useState, useCallback, useEffect } from 'react';
import { ApiService, BASE_URL }  from '../services/ApiService';
import User  from '../services/User';
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import Drawflow from 'drawflow'
import styleDrawflow from 'drawflow/dist/drawflow.min.css'
import Select from 'react-select'
import LoadingContext from '../ToggleContext';
import { Form } from 'react-bootstrap';


const mSwal = withReactContent(Swal)

function withParams(Component) {
  return props => <Component {...props} params={useParams()} />;
}

// export default function FlowEditor() {
export class FlowEditor extends Component {

  static contextType = LoadingContext
    constructor(props) {
      super(props)
      this.state = {
        data:{},
        groups:[],
        templates:[],
        drawflowContainer:null,
        hasTrigger:false,
        autId:'',
        avlCampaigns:[]
      };
      this.drawflowContainer = React.createRef();
      this.drawflow = null;
      this.node_types = [
        'when-user-subscribes',
        'when-user-opens',
        'when-user-clicks',
        'add-condition',
        'add-delay',
        'add-template',
        'group-copy',
        'group-move',
        'group-remove',
        'unsubscribe',
      ];
    } 

  async componentDidMount() {
    if(this.props.params.autId) this.loadData(this.props.params.autId);
    let user = User.get();

    let account = await ApiService.get(`fairymailer/getAccount`,user.jwt)
    account = account.data.user.account;
    this.loadGroups(account.id);
    this.loadTemplates(account.id);
  }
  loadData = async (autId)=>{
    let user = User.get();
    this.setState({autId:autId})
    let resp = await ApiService.get(`automations?filters[uuid]=${autId}&populate=*`, user.jwt);
    this.setState({data:resp.data.data[0]})
    console.log(this.state.data)
    if(this.drawflow && resp.data.data[0].attributes.design){
      this.drawflow.import(resp.data.data[0].attributes.design)
      let data = this.drawflow.export();
      console.log(data)
      let hasTrigger = false;
      Object.keys(data.drawflow.Home.data).forEach(key=>{
        if(data.drawflow.Home.data[key].name.includes('when-')){
          hasTrigger = true;
        }
      })
      this.setState({hasTrigger:hasTrigger})
    }
    this.context.setLoading_(false);
  }
 loadGroups = async (accountid=0)=>{
    let user = User.get();
    console.log('user',user);
    let resp = await ApiService.get(`groups?polulate=*&filters[account]=${accountid}`, user.jwt);
    this.setState({groups:resp.data.data.map(v=>{return {value:v.id,label:v.attributes.name};})})
    console.log(this.state.groups)
  }
  loadTemplates = async (accountid=0)=>{
    let user = User.get();
    console.log('user',user)
    let resp = await ApiService.get(`templates?polulate=*&filters[account]=${accountid}`, user.jwt);
    this.setState({templates:resp.data.data.map(v=>{return {value:v.id,label:v.attributes.name};})})
  }
  drag = (ev) => {
    if (ev.type === "touchstart") {
      // mobile_item_selec = ev.target.closest(".drag-drawflow").getAttribute('data-node');
    } else {
    ev.dataTransfer.setData("node", ev.target.getAttribute('data-node'));
    }
  }
  drop = (ev) => {
    if (ev.type === "touchend") {
      alert(ev.type);
      // var parentdrawflow = document.elementFromPoint( mobile_last_move.touches[0].clientX, mobile_last_move.touches[0].clientY).closest("#drawflow");
      // if(parentdrawflow != null) {
      //   addNodeToDrawFlow(mobile_item_selec, mobile_last_move.touches[0].clientX, mobile_last_move.touches[0].clientY);
      // }
      // mobile_item_selec = '';
    } else {
      ev.preventDefault();
      // alert('Node '+ev.dataTransfer.getData("node"));
      var data = ev.dataTransfer.getData("node"); 
      this.addNodeToDrawFlow(data, ev.clientX, ev.clientY);
    }
  }
  doubleClick = (ev) =>{
    const data = ev.target.closest('.drag-drawflow').getAttribute('data-node');
    let allNodes = [];
    this.node_types.forEach(type=>{
      allNodes = allNodes.concat(this.drawflow.getNodesFromName(type));
    })
    console.log('nodes',allNodes)
    let newPosX = 0;
    let newPosY = 0;
    let latestNode = null;
    if (allNodes.length > 0) {
      // Get the node with the maximum pos_y
      let latestNodeId = allNodes.reduce((maxNodeId, nodeId) => {
          let maxNode = this.drawflow.getNodeFromId(maxNodeId);
          let currentNode = this.drawflow.getNodeFromId(nodeId);
          return (currentNode.pos_y > maxNode.pos_y) ? nodeId : maxNodeId;
      });

      latestNode = this.drawflow.getNodeFromId(latestNodeId);
      console.log('latestNode',latestNode)
      // Position the new node 50px down from the latest node
      newPosX = latestNode.pos_x;
      newPosY = latestNode.pos_y + 180;
  } else {
      // Center the first node at the top
      let canvasWidth = this.drawflow.precanvas.clientWidth;
      newPosX = (canvasWidth / 2) - 200;  // Assuming node width is around 200px
      newPosY = 0;
  }
    this.addNodeToDrawFlow(data, newPosX, newPosY,true, latestNode);
  }
  allowDrop = (ev) => {
    ev.preventDefault();
  }


  extractLinksFromCampaignDesign(components=[],links=[]){
    for(let c=0;c<components.length;c++){
      if(components[c].components && components[c].components.length>0) links = [...this.extractLinksFromCampaignDesign(components[c].components,links)];
      if(components[c].type && components[c].type=="link"){
        links.push(components[c]?.attributes?.href)
      }
    }
    return links;
  }

  async handleNodeDataChanged(id){
    let node = this.drawflow.getNodeFromId(id);
    const user = User.get();
    let account = await ApiService.get(`fairymailer/getAccount`,user.jwt)
    account = account.data.user.account;
    if(node.name=="add-condition"){
      console.log(node.data)
      switch(node.data.condition){
        case "cmp-open": 
        if(document.querySelector(`#node-${id} select.link-select`)) document.querySelector(`#node-${id} select.link-select`).remove();

        if(!node.data.campaignid){
          this.context.setLoading_(true);
          mSwal.fire({icon:'info',showLoading:true,didOpen: async ()=>{
            
            Swal.showLoading();
            let campaigns = await ApiService.get(`campaigns?filters[account]=${account.id}&pagination[pageSize]=100&pagination[page]=1&sort[createdAt]=desc`,user.jwt)
            console.log('campaigns avl',campaigns.data)
            this.setState({avlCampaigns:campaigns.data.data})
            document.querySelector(`#node-${id} select`).insertAdjacentHTML('afterend',`
                <br><select df-campaignid class="cmp-select">
                <option value="">-- Select a campaign --</option>
                ${this.state.avlCampaigns.map(cmp=>{
                    return `<option value=${cmp.id}>${cmp.attributes.name} (#${cmp.id})</option>`
                })}
                </select>
                ` );
            mSwal.fire({icon:'success',timer:400})
            this.context.setLoading_(false);
          }})
        } 

        break;
        case "link-click": 
        if(document.querySelector(`#node-${id} select.cmp-select`)) document.querySelector(`#node-${id} select.cmp-select`).remove();
        if(!node.data.link){
          this.drawflow.updateNodeDataFromId(id,{condition:''})
          mSwal.fire({icon:'info',showLoading:true,didOpen: async ()=>{
            let user = User.get();
            Swal.showLoading();
            let campaigns = await ApiService.get(`campaigns?filters[account]=${account.id}&pagination[pageSize]=100&pagination[page]=1&sort[createdAt]=desc`,user.jwt)
            console.log('campaigns avl',campaigns.data)
            this.setState({avlCampaigns:campaigns.data.data})
            let links = [];
            this.state.avlCampaigns.forEach(cmp=>{
              if(cmp.attributes && cmp.attributes && cmp.attributes.design){
                let dsgn = JSON.parse(cmp.attributes.design);
                let cmplinks = this.extractLinksFromCampaignDesign(dsgn.components);
                if(cmplinks.length>0) links = [...cmplinks]
              }
            })
            console.log(links)
            document.querySelector(`#node-${id} select`).insertAdjacentHTML('afterend',`
                <br><select df-link class="link-select">
                <option value="">-- Select a link --</option>
                ${links.map(l=>{
                  if(l)
                    return `<option value=${l}>${this.shortenString(l)}</option>`
                })}
                </select>
                ` );
            mSwal.fire({icon:'success',timer:400})
            this.context.setLoading_(false);
          }})
        }
      }
    }
  }
  shortenString(str) {
    const maxLength = 50;
    if (str.length <= maxLength) {
        return str;
    }
    
    const partLength = Math.floor((maxLength - 3) / 2);
    const start = str.substring(0, partLength);
    const end = str.substring(str.length - partLength);
    
    return `${start} ... ${end}`;
}
  handleNodeRemoved (id){
    let data = this.drawflow.export();
    let hasTrigger = false;
    Object.keys(data.drawflow.Home.data).forEach(key=>{
      if(data.drawflow.Home.data[key].name.includes('when-')){
        hasTrigger = true;
      }
    })
    if(!hasTrigger){
      this.drawflow.clear();
    }
    this.setState({hasTrigger:hasTrigger})
  }

  addNodeToDrawFlow = (name, pos_x, pos_y, doubleClick=false, latestNode=null) => {
    if(!this.drawflow){
      let mdrawflow = new Drawflow(this.drawflowContainer.current);
      mdrawflow.start();
      this.drawflow = mdrawflow;
    }
    if( this.drawflow.editor_mode === 'fixed') {
      return false;
    }
    if(!doubleClick){
      pos_x = pos_x * ( this.drawflow.precanvas.clientWidth / (this.drawflow.precanvas.clientWidth * this.drawflow.zoom)) - (this.drawflow.precanvas.getBoundingClientRect().x * ( this.drawflow.precanvas.clientWidth / (this.drawflow.precanvas.clientWidth * this.drawflow.zoom)));
      pos_y = pos_y * ( this.drawflow.precanvas.clientHeight / (this.drawflow.precanvas.clientHeight * this.drawflow.zoom)) - (this.drawflow.precanvas.getBoundingClientRect().y * ( this.drawflow.precanvas.clientHeight / (this.drawflow.precanvas.clientHeight * this.drawflow.zoom)));
    }
    switch (name) {
      case 'when-user-opens':
      case 'when-user-clicks': 
        mSwal.fire({icon:'info',text:'Oopsss :/ Not implemented yet',timer:3000});
      break;
      case 'when-user-subscribes':
        console.log('groups',this.state.groups)
        var subscribes = `
          <div>
            <div class="title-box"><i class="fa fa-user-plus"></i> When user subscribes</div>
            <div class="box">
              <p>to group:</p>
             <select df-group>
                <option value="none">Select a group..</option>
                ${
                  this.state.groups && this.state.groups.length>0
                  ? (this.state.groups.map(grp=>{
                    return `<option value="${grp.value}">${grp.label}</option>`
                  }))
                  : ''
                }
              </select>
            </div>
          </div> `;
          console.log(subscribes)
        this.drawflow.addNode(name, 0, 1, pos_x, pos_y, name, { "group": 'none'}, subscribes.toString() );
        this.setState({hasTrigger:true})
        break;
      case 'add-condition':
        var condition = `
          <div>
          <div class="title-box"><i class="fa fa-arrows-h"></i> Add a condition: </div>
          <div class="box" style="display:flex; justify-content:space-around;align-items:center; flex-direction:column;">
            <p> When user </p>
            <select df-condition>
                <option value="">-- Choose --</option>
                <option value="cmp-open">Has opened a campaign</option>
                <option value="link-click">Has clicked on a link</option>
            </select>
            <p> Then </p>
          </div>
        </div> `;
        this.drawflow.addNode(name, 1, 2, pos_x, pos_y, name, { condition: '' }, condition );
        break;
      case 'add-delay':
        var delay = `
          <div>
          <div class="title-box"><i class="fa fa-clock"></i> Add a delay of </div>
          <div class="box" style="display:flex; justify-content:space-around">
            <input type="number" min="1" max="48" df-delay />
            <p>hours. Then: </p>
          </div>
        </div> `;
        this.drawflow.addNode(name, 1, 1, pos_x, pos_y, name, { "delay": 1 }, delay.toString() );
        break;
      case 'add-template':
        var template = `
          <div>
          <div class="title-box"><i class="fa fa-email"></i> Send a template: </div>
          <div class="box" style="">
            <select df-template>
              <option value="none">Select a template..</option>
              ${
                this.state.templates && this.state.templates.length>0
                ? (this.state.templates.map(grp=>{
                  return `<option value="${grp.value}">${grp.label}</option>`
                }))
                : ''
              }
            </select>
          </div>
          <hr>
          <div class="title-box"><i class="fa fa-email"></i> Or create a new one <a href="/templates/edit/new/${this.state.autId}" target="_self" class="btn btn-sm btn-primary">by clicking here</a></div>
        </div> `;
        this.drawflow.addNode(name, 1, 1, pos_x, pos_y, name, { "template": 'none' }, template.toString() );
        break;
      case 'group-copy':
        var copy = `
        <div>
            <div class="title-box"><i class="fa fa-user-plus"></i> Copy subscriber to group..</div>
            <div class="box">
              <select df-group>
                <option value="none">Select a group..</option>
                ${
                  this.state.groups && this.state.groups.length>0
                  ? (this.state.groups.map(grp=>{
                    return `<option value="${grp.value}">${grp.label}</option>`
                  }))
                  : ''
                }
              </select>
            </div>
          </div> `;
        this.drawflow.addNode(name, 1, 1, pos_x, pos_y, name, { "group": 'none' }, copy.toString() );
        break;
      case 'group-move':
        var move = `
        <div>
            <div class="title-box"><i class="fa fa-user-plus"></i> Move subscriber to group..</div>
            <div class="box">
              <select df-group>
                <option value="none">Select a group..</option>
                ${
                  this.state.groups && this.state.groups.length>0
                  ? (this.state.groups.map(grp=>{
                    return `<option value="${grp.value}">${grp.label}</option>`
                  }))
                  : ''
                }
              </select>
            </div>
          </div> `;
        this.drawflow.addNode(name, 1, 1, pos_x, pos_y, name, { "group": 'none' }, move.toString() );
        break;
      case 'group-remove':
        var remove = `
        <div>
            <div class="title-box"><i class="fa fa-user-times"></i> Remove subscriber from group..</div>
            <div class="box">
              <select df-group>
                <option value="none">Select a group..</option>
                ${
                  this.state.groups && this.state.groups.length>0
                  ? (this.state.groups.map(grp=>{
                    return `<option value="${grp.value}">${grp.label}</option>`
                  }))
                  : ''
                }
              </select>
            </div>
          </div> `;
        this.drawflow.addNode(name, 1, 1, pos_x, pos_y, name, { "group": 'none' }, remove.toString() );
        break;
      case 'unsubscribe':
        var unsubscribe = `
          <div>
          <div class="title-box"><i class="fa fa-clock"></i> Unsubscribe user </div>
          <div class="box" style="display:flex; justify-content:space-around">
            <p> * this action is irreversible.</p>
          </div>
        </div> `;
        this.drawflow.addNode(name, 1, 1, pos_x, pos_y, name, { }, unsubscribe.toString() );
        break;
      default:
    }
    
  }
  
  async exportData(){
    let data = this.drawflow.export();
    console.log(data)
    let hasTrigger = false;
    Object.keys(data.drawflow.Home.data).forEach(key=>{
      if(data.drawflow.Home.data[key].name.includes('when-')){
        hasTrigger = true;
      }
    })
    if(!hasTrigger){
      mSwal.fire({icon:'error',text:'You need to add a trigger event first.'});
      return;
    }
    let invalid=false;
    Object.keys(data.drawflow.Home.data).forEach(key=>{
      if(data.drawflow.Home.data[key].data.group && 'none'==data.drawflow.Home.data[key].data.group) invalid=true;
      if(data.drawflow.Home.data[key].data.template && 'none'==data.drawflow.Home.data[key].data.template) invalid=true;
      console.log(data.drawflow.Home.data[key].data)
    })
    if(invalid){
      mSwal.fire({icon:'error',text:'You have empty actions in your flow. You can delete unwanted actions by right-clicking them.'});
      return;
    }
    console.log(data);
    mSwal.fire({
      icon:'info',
      text:'Please wait...',
      didOpen: async () => {
        Swal.showLoading();
        let user = await User.get();
        let newdata = {
          data:{
           design:data,
           active:this.state.data.attributes.active
          }
        }
        let resp
        if(this.state.data && this.state.data.id>0){
          resp = await ApiService.put(`automations/${this.state.data.id}`,newdata,user.jwt)
        }else{
          alert('error');
        }
        console.log(resp)
        if(resp.data && resp.data.data.id){
          // window.location.href = `/automations/all`;
          this.drawflow.import(data)
          mSwal.fire({icon:'success',timer:500});
          return;
        }
        mSwal.fire({icon:'error',text:`Failed to save your changes. If this problem persists, contact our support team.`})
      }
    })
    
  }
  render (){
    const {hasTrigger} = this.state
    if(!this.drawflow && this.drawflowContainer && this.drawflowContainer.current){
      let mdrawflow = new Drawflow(this.drawflowContainer.current);
      mdrawflow.start();
      this.drawflow = mdrawflow;
      this.drawflow.on('nodeRemoved', (id)=>this.handleNodeRemoved(id));
      this.drawflow.on('nodeDataChanged', (id)=>this.handleNodeDataChanged(id));
      console.log(this.state)
      // if(this.state.data.attributes.design){
      //   this.drawflow.import(this.state.data.attributes.design);
      //   alert()
      // }
    }
    return (<div style={{display:'flex', flexDirection:'row'}}>
      <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.css" integrity="sha512-5A8nwdMOWrSz20fDsjczgUidUBR8liPYU+WymTZP1lmY9G6Oc7HlZv156XqnsgNUzTyMefFTcsFH/tnJE/+xBg==" crossorigin="anonymous" referrerpolicy="no-referrer" />
      <div ref={this.drawflowContainer} className="drawflow" id='drawflow' style={{ background:'white', width: '80%', height: '100vh' }}  onDoubleClick={(event)=>{this.doubleClick(event)}}  onDrop={(event)=>{this.drop(event)}} onDragOver={(event)=>this.allowDrop(event)}/>
      <div className='flow-sidebar' style={{width:'20%', height: '100vh'}}>
        {
          !hasTrigger ? 
        (<div>
          <h3>Select a trigger</h3>
          <div className="drag-drawflow" draggable={true}  onDoubleClick={(event)=>{this.doubleClick(event)}} onDragStart={(event)=>this.drag(event)} data-node="when-user-subscribes">
            <span>
            <i class="fa fa-users"></i>&nbsp;&nbsp;When subscriber joins a group
                <small> Workflow triggered when a subscriber joins your subscriber group. </small>
            </span>
          </div>
          <div className="drag-drawflow" draggable={true} onDoubleClick={(event)=>{this.doubleClick(event)}}  onDragStart={(event)=>{this.drag(event)}} data-node="when-user-opens">
            <span>
            <i class="fa fa-list-alt"></i>&nbsp;&nbsp;When subscriber opens a campaign
                <small> Workflow triggered when a subscriber opens a campaign sent to them. </small>
            </span>
          </div>
          <div className="drag-drawflow" draggable={true} onDoubleClick={(event)=>{this.doubleClick(event)}}  onDragStart={(event)=>{this.drag(event)}} data-node="when-user-clicks">
            <span>
            <i class="fa fa-link"></i>&nbsp;&nbsp;When subscriber clicks a link
                <small> Workflow triggered when a subscriber clicks a link in any campaign. </small>
            </span>
          </div>
        </div>)
           : 
            (<div>
              <h3>Conditions</h3>
        <div className="drag-drawflow"  onDoubleClick={(event)=>{this.doubleClick(event)}}  draggable={true} onDragStart={(event)=>{this.drag(event)}} data-node="add-condition">
          <span>Add a condition</span>
        </div>
  
        <h3>Actions</h3>
        <div className="drag-drawflow"  onDoubleClick={(event)=>{this.doubleClick(event)}} draggable={true} onDragStart={(event)=>{this.drag(event)}} data-node="add-delay">
          <span>Delay</span>
        </div>
  
        <div className="drag-drawflow"  onDoubleClick={(event)=>{this.doubleClick(event)}} draggable={true} onDragStart={(event)=>{this.drag(event)}} data-node="add-template">
          <span>Send a template</span>
        </div>
  
        <div className="drag-drawflow"  onDoubleClick={(event)=>{this.doubleClick(event)}} draggable={true} onDragStart={(event)=>{this.drag(event)}} data-node="group-copy">
          <span>Copy to group</span>
        </div>
      
        <div className="drag-drawflow"  onDoubleClick={(event)=>{this.doubleClick(event)}} draggable={true} onDragStart={(event)=>{this.drag(event)}} data-node="group-move">
          <span>Move to group</span>
        </div>
  
        <div className="drag-drawflow"  onDoubleClick={(event)=>{this.doubleClick(event)}} draggable={true} onDragStart={(event)=>{this.drag(event)}} data-node="group-remove">
          <span>Remove from group</span>
        </div>
  
        <div className="drag-drawflow"  onDoubleClick={(event)=>{this.doubleClick(event)}} draggable={true} onDragStart={(event)=>{this.drag(event)}} data-node="unsubscribe">
          <span>Unsubscribe</span>
        </div>
        <div className='flow-active-toggle' draggable={false}>
          <Form.Group>
              <Form.Check // prettier-ignore
                  type="switch"
                  label="Automation status"
                  id="disabled-custom-switch"
                  checked={this.state.data?this.state.data.attributes.active : false}
                  onChange={evt => {let automation = this.state.data; automation.attributes.active=evt.target.checked;this.setState({data:automation})}}
                />
            </Form.Group>
        </div>
        <div className='flow-save-btn btn btn-lg btn-success' draggable={false} onClick={()=>{this.exportData()}}>
          Save Flow
        </div>
            </div>)
           }
        
       
      </div>
      </div>
      );
    }
  }
export default withParams(FlowEditor)

// export class FlowEditor extends Component {
//   render() {
//     const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
//     const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);
    
//     const onConnect = useCallback(
//           (params) => setEdges((eds) => addEdge(params, eds)),
//           [setEdges],
//         );
//     return (
//       <div style={{ width: '100vw', height: '80vh' }}>
//         <ReactFlow
//           nodes={nodes}
//           edges={edges}
//           onNodesChange={onNodesChange}
//           onEdgesChange={onEdgesChange}
//           onConnect={onConnect}
//         >
//           <NodeToolbar />
//           <Controls />
//           <MiniMap />
//           <Background variant="dots" gap={12} size={1} />
//         </ReactFlow>
//       </div>
//     );
//   }

// }

// export default withParams(FlowEditor)
