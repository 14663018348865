import React, { Component, useEffect, useState } from "react";
import User from "../services/User";
import {ApiService} from "../services/ApiService";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { useParams } from 'react-router-dom';
import LoadingContext from "../ToggleContext";
import {Pagination} from 'react-bootstrap'

import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2'
const mSwal = withReactContent(Swal)


function withParams(Component) {
  return props => <Component {...props} params={useParams()} />;
}


export class AllGroups extends Component {
  static contextType = LoadingContext;
  constructor(props) {
    super(props)
    this.state = {
      skeletons:[{},{},{},{}],
      account_id:0,
      currentPage:1,
      itemsPerPage:20,
      groups: [],
      meta: [],
      error: null,
    };
  }

  async componentDidMount() {
    let user = User.get();
    let account = await ApiService.get(`fairymailer/getAccount`,user.jwt)
    account = account.data.user.account;
    console.log(account)
    this.setState({account_id:account.id});
    this.getGroups();
  }

  async editGroup(group_id){
    window.location.href=`/subscribers/groups/${group_id}`
  }
  async showSubs(group_udid){
    window.location.href=`/subscribers/${group_udid}`
  }

  async getGroups(page=1) {
    let user = User.get();
    let resp = await ApiService.get(`fairymailer/getGroups/?populate[subscribers][count]=true&pagination[page]=${page}&pagination[pagerSize]=${this.state.itemsPerPage}`, user.jwt);
    this.context.setLoading_(false);
    console.log('groups',resp);
    if (resp.data && resp.data.data) {
      this.setState({ groups: resp.data.data, meta:resp.data.meta });
    }
  }

  async deleteGroup(guid,name){
    mSwal.fire({
      icon:'question',
      text:`Are you sure you want to delete group ${name}?`,
      showConfirmButton:false,
      showDenyButton:true,
      showCancelButton:true,
      denyButtonText:'Yes, delete it',
      cancelButtonText:'No, Cancel',
      focusCancel:true,
    }).then(async res=>{
      if(res.isDenied){
        mSwal.fire({icon:'info',didOpen: async ()=>{
          Swal.showLoading();
          this.context.setLoading_(true);
          let rr = await ApiService.post(`fairymailer/deleteGroupByGuid/${guid}`,{},User.get().jwt);
          console.log(rr);
          if(rr.data && rr.data.code==200){
            mSwal.fire({icon:'success',timer:500}).then(async ()=>{
              await this.getGroups();
            })
          }else{
            mSwal.fire({icon:'error',text:'Failed to delete group. Please contact our support team.'});
            this.context.setLoading_(false);
          }
        }})
      }
    })
  }



  render() {
    const { skeletons, groups, meta, error } = this.state;

    return (
      <div className="groups-container">
        <div className="row">
          <div className="col-sm-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <div className="card-title">
                  <h2 className="">Groups</h2>
                  <button type="button" className="btn btn-success"  onClick={ ()=>{window.location.href='/subscribers/groups/new'} }>
                      <i className="mdi mdi-message-plus"></i> New
                  </button> &nbsp;
                </div>
                <div className="table-responsive">
                  <table className="table table-bordered table-striped">
                    <thead>
                      <tr>
                        <th>Group Name</th>
                        <th>Subs Count</th>
                        <th>Api UDID</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      { groups && groups.length > 0 ? (
                        groups.map((grp, index) => {
                         return ( <tr>
                            <td>{grp.name}</td>
                            <td>{grp.subscribers.count}</td>
                            <td> {grp.udid} </td>
                            <td>
                              <button type="button" className="btn btn-warning" onClick={ () => {this.editGroup(grp.udid) }}>
                                <i className="mdi mdi-grease-pencil"></i>Edit
                              </button> &nbsp;
                              <button type="button" className="btn btn-primary" onClick={ () => {this.showSubs(grp.udid) }}>
                                <i className="mdi mdi-account-group"></i>Subscribers </button> &nbsp;
                              <button type="button" className="btn btn-danger" onClick={ () => {this.deleteGroup(grp.udid, grp.name) }}>
                                <i className="mdi mdi-delete-forever-outline"></i>Delete </button> &nbsp;
                            </td>
                          </tr>)
                        })
                      ) : (
                        skeletons.map((cmp, index) => { return (
                        <tr>
                          <td><Skeleton /> </td>
                          <td><Skeleton /> </td>
                          <td><Skeleton /> </td>
                          <td><Skeleton /> </td>
                        </tr> )
                        }
                      ) )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Pagination>{Array.from({length: this.state.meta.pagination?.pageCount}, (_, i) => i + 1).map(num=>{
            return (<Pagination.Item key={num} active={num === this.state.meta.pagination?.page} onClick={()=>{this.getGroups(num)}}>{num}</Pagination.Item>)
          })}</Pagination>
      </div>
    );
  }
}

export default withParams(AllGroups);
