import React, { Component, useState } from 'react';
import { Link } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import {ApiService} from '../services/ApiService'
import User from '../services/User'
import LoadingContext from '../ToggleContext';
export class Login extends Component {
  state;
  static contextType=LoadingContext
  constructor(props) {
    super(props);

    this.state = {
        username: '',
        password: ''
    };
  }
  componentDidMount(){
    this.context.setLoading_(false);
  }
  handleInputChange = (event) => {
    const { name, value } = event.target;
    console.log(name,value)
    this.state[name] = value;
  };
  async login(){
    try {
      let response = await ApiService.login({username:this.state.username,password:this.state.password})
      let accounts = await ApiService.get(`fairymailer/getAccount`,response.data.jwt)
      let finaluser = response.data;
      finaluser.user_role = accounts.data.user_role;
      User.set(finaluser)
      User.setAccounts(accounts.data.user.accounts)
      // console.log(response);
      window.location.href="/dashboard"
      return;
    } catch (error) {
      // Handle error
      if (error.response) {
        // The request was made, but the server responded with a status code that falls out of the range of 2xx
        if(error.response.data.error.details.errors)alert(error.response.data.error.details.errors.map(err=>{return err.message}).join(', '));
        else alert(error.response.data.error.message);
      } else if (error.request) {
        // The request was made but no response was received
        console.error('Request Error:', error.request);
      } else {
        console.error('Error:', error.message);
      }
    }
  }
  render() {
    return (
      <div>
        <div className="d-flex align-items-center auth px-0">
          <div className="row w-100 mx-0">
            <div className="col-lg-4 mx-auto">
              <div className="card text-left py-5 px-4 px-sm-5">
                <div className="brand-logo">
                  <img src={require("../../assets/images/cf-logo-white.png")} alt="logo" />
                </div>
                <h4></h4>
                <h6 className="font-weight-light">Welcome back.</h6>
                <Form className="pt-3">
                  <Form.Group className="d-flex search-field">
                    <Form.Control type="text" placeholder="username" size="lg" name="username" onChange={this.handleInputChange} className="h-auto" />
                  </Form.Group>
                  <Form.Group className="d-flex search-field">
                    <Form.Control type="password" placeholder="password" size="lg" name="password" onChange={this.handleInputChange} className="h-auto" />
                  </Form.Group>
                  <div className="mt-3">
                    <Link className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn" onClick={(event)=>{event.preventDefault();this.login()}}>SIGN IN</Link>
                  </div>
                  <div className="my-2 d-flex justify-content-between align-items-center">
                    {/* <div className="form-check">
                      <label className="form-check-label text-muted">
                        <input type="checkbox" className="form-check-input"/>
                        <i className="input-helper"></i>
                        Keep me signed in
                      </label>
                    </div> */}
                    <a href="/password/forgot" className="auth-link text-muted">Forgot password?</a>
                  </div>
                  {/* <div className="mb-2">
                    <button type="button" className="btn btn-block btn-facebook auth-form-btn">
                      <i className="mdi mdi-facebook mr-2"></i>Connect using facebook
                    </button>
                  </div>
                  <div className="text-center mt-4 font-weight-light">
                    Don't have an account? <Link to="/user-pages/register" className="text-primary">Create</Link>
                  </div> */}
                </Form>
              </div>
            </div>
          </div>
        </div>  
      </div>
    )
  }
}

export default Login
