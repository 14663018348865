const cf_unsubscribe_component = `
    <div style="padding:10px;font-family:'Roboto', 'Arial', 'Helvetica', sans-serif;;font-size:19px;line-height:18.5px;text-align:right;background-color:#ffffff00;color:#000000;border-color:#000000;">
        <span id="i7b2b9" data-gjs-type="text" draggable="true" class="">
            You received this email because you signed up on our website or made a purchase from us.
        </span>
        <br id="if142z" data-gjs-type="default" draggable="true">
        <span id="ia4mbh" data-gjs-type="text" draggable="true">
            <u id="iw75sz" data-gjs-type="default" draggable="true">
                <a id="is41t3" data-gjs-type="link" draggable="true" href="{{#UNSUBSCRIBE_LINK#}}" class="">Unsubscribe</a>
            </u>
        </span>
    </div>
`
export default cf_unsubscribe_component;