import React, { Component, useEffect, useState } from 'react';
import Select from 'react-select';

const RcpFilter = ({ id, campaigns, links, onComplete }) =>{

  
    
    const [key,setKey] = useState('')
    const [condition,setCondition] = useState('')
    const [value,setValue] = useState('')


    useEffect(()=>{
        console.log(key?.value)
        console.log(value?.value)
        console.log(condition?.value)
        if(
            key && key.value && key.value.length>0 
            && condition && condition.value && condition.value.length>0 
            && value && value.value && value.value.length>0){
         switch(key.value){
             case "ocmp": 
                 switch(condition.value){
                     case "contains": 
                         onComplete(id,{
                             $or: [
                                 { ocmp_ids: { $contains: value.value } },
                             ]
                         })
                     break;
                     case "notContains": 
                         onComplete(id,{
                             $or: [
                                 { ocmp_ids: { $notContains: value.value } },
                                 { ocmp_ids: { $null: true } }
                             ]
                         })
                     break;
                     default:
                        console.log('defauld condition ocmp')
                 }
             break;
             case "link": 
                 switch(condition.value){
                     case "contains": 
                         onComplete(id,{
                             $or: [
                                 { links_clicked: { $contains: value.value } },
                             ]
                         })
                     break;
                     case "notContains": 
                         onComplete(id,{
                             $or: [
                                 { links_clicked: { $notContains: value.value } },
                                 { links_clicked: { $null: true } }
                             ]
                         })
                     break;
                     default:
                    console.log('defauld condition link')
                 }
             break;
             default:
            console.log('defauld key')
         }
        }
    },[key,value,condition])
    return (
        <>
        <small> - And - </small>
        <br></br>
        <div className="rcp-filter-row" key={id}>
            <Select options={[
                {label:'Link of prev. cmp.',value:'link'},
                {label:'Previous Campaign',value:'ocmp'},
                ]} className="select-control" value={key} onChange={evt => {setKey(evt);}}/>
            <Select options={key.value=='ocmp' ? campaigns : links} className="select-control" value={value} onChange={evt => {setValue(evt);}}/>
            <Select options={[
                {label:`Was ${key.value=='ocmp' ? 'opened':'clicked'}`,value:'contains'},
                {label:`Was NOT ${key.value=='ocmp' ? 'opened':'clicked'}`,value:'notContains'},
                ]} className="select-control" value={condition} onChange={evt => {setCondition(evt);}}/>
        </div>
        </>
    );
}

export default RcpFilter;