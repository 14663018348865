import React, { Component, useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import {ApiService} from '../services/ApiService'
import User from '../services/User'
import LoadingContext from '../ToggleContext';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const mSwal = withReactContent(Swal)
function withParams(Component) {
  return props => <Component {...props} params={useParams()} />;
}
export class PasswordReset extends Component {
  state;
  static contextType = LoadingContext;
  constructor(props) {
    super(props);
    this.state = {
        identifier: ''
    };
  }
  componentDidMount() {
   this.context.setLoading_(false)
  }
  async doChangeUserPassword(){
    this.context.setLoading_(true);
    let resp = await ApiService.post(`fairymailer/reset-password-link`,{identifier:this.state.identifier});
    this.context.setLoading_(false);
    console.log(resp)
    if(resp.data.code==200){
      mSwal.fire({
        icon:'success',
        text:'A password reset link was sent to your inbox!',
        timer:6000
      }).then(()=>{
        window.location.href="/login"
      })
    }else{
      mSwal.fire({
        icon:'error',
        text:resp.data.message
      })
    }
  }
  handleInputChange = (event) => {
    const { name, value } = event.target;
    let st = this.state;
    st[name] = value;
    this.setState(st);
  };
  
  render() {
    return (
      <div>
        <div className="d-flex align-items-center auth px-0">
          <div className="row w-100 mx-0">
            <div className="col-lg-4 mx-auto">
              <div className="card text-left py-5 px-4 px-sm-5">
                <div className="brand-logo">
                  <img src={require("../../assets/images/cf-logo-white.png")} alt="logo" />
                </div>
                <h4></h4>
                <h6 className="font-weight-light">Password Recovery</h6>
                <Form className="pt-3">
                  <Form.Group className="d-flex search-field">
                    <Form.Control type="text" placeholder="Your username or email" size="lg" name="identifier" onChange={this.handleInputChange} className="h-auto" />
                  </Form.Group>
                  <br></br>
                  <small>You will receive a reset link in your inbox.</small>
                  <div className="mt-3">
                    <Link className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn"  onClick={(event)=>{event.preventDefault();this.doChangeUserPassword()}}>Reset Password</Link>
                  </div>
                  <div className="my-2 d-flex justify-content-between align-items-center">
                    {/* <div className="form-check">
                      <label className="form-check-label text-muted">
                        <input type="checkbox" className="form-check-input"/>
                        <i className="input-helper"></i>
                        Keep me signed in
                      </label>
                    </div> */}
                    {/* <a href="!#" onClick={event => event.preventDefault()} className="auth-link text-muted">Forgot password?</a> */}
                  </div>
                  {/* <div className="mb-2">
                    <button type="button" className="btn btn-block btn-facebook auth-form-btn">
                      <i className="mdi mdi-facebook mr-2"></i>Connect using facebook
                    </button>
                  </div>
                  <div className="text-center mt-4 font-weight-light">
                    Don't have an account? <Link to="/user-pages/register" className="text-primary">Create</Link>
                  </div> */}
                </Form>
              </div>
            </div>
          </div>
        </div>  
      </div>
    )
  }
}

export default withParams(PasswordReset)
