const cf_footer_signature = `
<div style="padding:10px;text-align:left;">
          <b id="igeles" data-gjs-type="text" draggable="true">
            {{#ACCOUNT_NAME#}}
            <br id="imnnct" data-gjs-type="default" draggable="true">
          </b>
          <u id="izjw9p" data-gjs-type="default" draggable="true">
            <a id="ib69pr" data-gjs-type="link" draggable="true" href="mailto:{{#ACCOUNT_EMAIL#}}" class="">
              {{#ACCOUNT_EMAIL#}}
            </a>
          </u><br>
          <u id="i74htf" data-gjs-type="default" draggable="true">
            <a id="ig9wfr" data-gjs-type="link" draggable="true" href="{{#ACCOUNT_WEBSITE#}}">
             {{#ACCOUNT_WEBSITE#}}
            </a>
          </u>
        </div>

`;

export default cf_footer_signature;;