import React, { Component, useState } from 'react';
import Select from 'react-select';

const FontPicker = ({ fontsRaw, font_opts, onFontSelected }) =>{
    const [selected,setSelected] = useState(0)
    const [sampleText,setSampleText] = useState('Lorem ispum dolor sit amet')
    const [style,setStyle] = useState({})
    return (
        <div id={'fonts-picker'}>
            <h4>Select a Google Font to add</h4>
            <Select options={font_opts} onChange={(e)=>{
                    setSelected(e.value);
                    onFontSelected(e.value)
                    const styleElement = document.createElement('style');
                    styleElement.innerHTML =  ` @import url('https://fonts.googleapis.com/css2?family=${fontsRaw[e.value].family.toString().split(' ').join('+')}:wght@400;700&display=swap') `
                    document.querySelector("#fonts-picker").appendChild(styleElement)
                    setStyle({fontFamily:`${fontsRaw[e.value].family.toString()}`});
                }} isSearchable />
                <br></br>
                <div className='form-group'>
                <label>Type something:</label>
                <input className="form-control" style={{background:'white',color:"#333333",borderColor:'#22222250'}} value={sampleText} onChange={e=>{setSampleText(e.target.value)}} />
                </div>
                <div className='form-group'>
                <label>Preview:</label>
                <p style={style} className='preview preview-normal'>{sampleText}</p>
                <p style={style} className='preview preview-italics'>{sampleText}</p>
                <p style={style} className='preview preview-bold'>{sampleText}</p>
                </div>

        </div>
    );
}

export default FontPicker;