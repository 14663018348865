import React, { Component, useEffect, useState } from "react";
import User from "../services/User";
import {ApiService} from "../services/ApiService";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { v4 as uuidv4 } from 'uuid';
import { useParams } from 'react-router-dom';
import LoadingContext from "../ToggleContext";
import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2'
import Select from 'react-select';


const mSwal = withReactContent(Swal)
function withParams(Component) {
  return props => <Component {...props} params={useParams()} />;
}
export class AllIntegrations extends Component {
  static contextType = LoadingContext;
  constructor(props) {
    super(props)
    this.state = {
      skeletons:[{},{},{},{},{},{},{},{},{}],
      integrations: [],
      getBookfunnelMaps:[],
      currentPage:1,
      itemsPerPage:20,
      autoStatus:'',
      meta: [],
      modal:{
        page:{id:''},
        group_id:0
      },
      error: null,
    };
  }

  componentDidMount() {
    // let autoStatus = this.props.params.autoType;
    // this.setState({autoStatus:autoStatus})
    // if(autoStatus && autoStatus=="drafts") this.getautomations(true);
    // else 
    this.getBookfunnelMaps();

  }

  async getBookfunnelMaps(page=1) {
    let user = User.get();
    let resp = await ApiService.get(`fairymailer/bookfunnel-maps/?pagination[page]=${page}&pagination[pagerSize]=${this.state.itemsPerPage}&populate[group]=true`, user.jwt);
    if (resp.data && resp.data.data) {
      console.log(resp.data.data);
      this.setState({ integrations: resp.data.data, meta:resp.data.meta });
    }else{
      mSwal.fire({icon:'error',text:'Failed to retrieve integrations.'})
    }
    this.context.setLoading_(false);
  }

  async deleteMap(id) {
    mSwal.fire({
      icon:'question',
      text:'Are you sure you want to delete this BookFunnel Mapping?',
      showCancelButton:true,
      cancelButtonText:'Cancel',
      focusCancel:true,
      confirmButtonText:'Delete it'
    }).then( async res=>{
      if(res.isConfirmed){
        this.context.setLoading_(true);
        let resp = await ApiService.delete('bookfunnel-maps/'+id,User.get().jwt)
        console.log(resp.data);
        window.location.reload()
      }
    })
  }
  async addMap(){
    const user = User.get();
    let account = await ApiService.get(`fairymailer/getAccount`,user.jwt)
    account = account.data.user.account;
    mSwal.fire({
      text:'Please wait...',
      didOpen: async()=>{
        Swal.showLoading();
        let refresh = await ApiService.get('fairymailer/bookfunnel-fetch',user.jwt);
        let groups = await ApiService.get(`fairymailer/getGroups/?populate[subscribers][count]=true&pagination[page]=1&pagination[pagerSize]=100`, user.jwt);

        console.log(refresh.data)
        this.setState({getBookfunnelMaps:refresh.data.data});
        mSwal.fire({
          customClass:`font-picker`,
          html: (
            <div>
              <h3>Select a BookFunnel Page:</h3>
              <Select options={this.state.getBookfunnelMaps.map(m=>{return {value:m,label:m.name}})} onChange={(e)=>{ 
                let modal = this.state.modal;
                modal.page = e.value;
                this.setState({modal:modal})
              }} isSearchable />
              <br></br>
              <h3>Select a Group to store new subsv:</h3>
              <Select options={groups.data.data.map(m=>{return {value:m.id,label:m.name}})} onChange={(e)=>{
                let modal = this.state.modal;
                modal.group_id = e.value;
                this.setState({modal:modal})
               }} isSearchable />
            </div>
          ),
          preConfirm: async ()=>{
            await ApiService.post('bookfunnel-maps',{data:{
              bf_page: this.state.modal.page,
              group: this.state.modal.group_id,
              account: account.id
            }},user.jwt)
            return this.state.modal
          },
          showConfirmButton:true,
          confirmButtonText:'OK'
        }).then(res=>{
          console.log(res.value);
          window.location.reload();
        })
        // let pages_available = await ApiService.get_external('https://fairymail.cobaltfairy.com/uploads/bookfunnel_pages.json');
        // console.log(pages_available);
        // mSwal.fire({icon:'success',timer:2000})
        // if(refresh.data.code==200){
        // }else{
        //   mSwal.fire({'icon':'error',text:'Failed to refresh BookFunnel Page ids :('});
        // }
      }
    })
  }

  render() {
    const { skeletons, integrations, autoStatus, meta, error } = this.state;

    return (
      <div className="automations-container">
        <div className="row">
          <div className="col-sm-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <div className="card-title">
                <h2 className="">BookFunnel Integration</h2>
                  <button type="button" className="btn btn-success"  onClick={ ()=>{this.addMap()} }>
                    <i className="mdi mdi-message-plus"></i> Add Mapping
                  </button>
                </div>
                <div className="table-responsive">
                  <table className="table table-bordered table-striped">
                    <thead>
                      <tr>
                        <th>BF Page</th>
                        <th>Account Group</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      { integrations && integrations.length > 0 ? (
                        integrations.map((auto, index) => {
                         return ( <tr>
                            <td>{auto.bf_page.name}</td>
                            <td>{auto.group.name}</td>
                            <td>
                              <button type="button" className="btn btn-danger" onClick={ () => {this.deleteMap(auto.id) }}>
                                Delete
                              </button> &nbsp;
                            </td>
                          </tr>)
                        })
                      ) : (
                        skeletons.map((auto, index) => { return (
                        <tr>
                          <td><Skeleton /> </td>
                          <td><Skeleton /> </td>
                          <td><Skeleton /> </td>
                          <td><Skeleton /> </td>
                          <td><Skeleton /> </td>
                          <td><Skeleton /> </td>
                        </tr> )
                        }
                      ) ) } 
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withParams(AllIntegrations);
