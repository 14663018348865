import React, { Component, useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import {ApiService} from '../services/ApiService'
import User from '../services/User'
import LoadingContext from '../ToggleContext';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const mSwal = withReactContent(Swal)
function withParams(Component) {
  return props => <Component {...props} params={useParams()} />;
}
export class PasswordForgot extends Component {
  state;
  static contextType = LoadingContext;
  constructor(props) {
    super(props);
    this.state = {
        user:{},
        token:'',
        password: '',
        password2: ''
    };
  }
  componentDidMount() {
    if(!this.props.params.token || this.props.params.token.length<5){
      mSwal.fire({
        icon:'error',
        text:'This link is either exepired or invalid.'
      }).then(()=>{
        window.location.href='/';
      })
    }else{
      this.fetchUserByToken(this.props.params.token)
    }
  }
  async fetchUserByToken(token){
    this.context.setLoading_(true);
    let resp = await ApiService.post(`fairymailer/fetch-user-by-password-token`,{token:token});
    this.context.setLoading_(false);
    console.log(resp)
    if(resp.data.code==200){
      this.setState({user:await resp.data.data, token:token})
    }else{
      mSwal.fire({
        icon:'error',
        text:'This link is either exepired or invalid.'
      }).then(()=>{
        window.location.href='/';
      })
    }
  }
  async doChangeUserPassword(){
    this.context.setLoading_(true);
    let resp = await ApiService.post(`fairymailer/do-change-user-password`,{token:this.state.token,password:this.state.password,password2:this.state.password2});
    this.context.setLoading_(false);
    console.log(resp)
    if(resp.data.code==200){
      mSwal.fire({
        icon:'success',
        text:'Password changed successfully!',
        timer:3000
      }).then(()=>{
        window.location.href="/login"
      })
    }else{
      mSwal.fire({
        icon:'error',
        text:resp.data.message
      })
    }
  }
  handleInputChange = (event) => {
    const { name, value } = event.target;
    let st = this.state;
    st[name] = value;
    this.setState(st);
  };
  
  async login(){
    // try {
    //   let response = await ApiService.login({username:this.state.username,password:this.state.password})
    //   let accounts = await ApiService.get(`fairymailer/getAccount`,response.data.jwt)
    //   let finaluser = response.data;
    //   finaluser.user_role = accounts.data.user_role;
    //   User.set(finaluser)
    //   User.setAccounts(accounts.data.user.accounts)
    //   // console.log(response);
    //   window.location.reload();
    //   return;
    // } catch (error) {
    //   // Handle error
    //   if (error.response) {
    //     // The request was made, but the server responded with a status code that falls out of the range of 2xx
    //     if(error.response.data.error.details.errors)alert(error.response.data.error.details.errors.map(err=>{return err.message}).join(', '));
    //     else alert(error.response.data.error.message);
    //   } else if (error.request) {
    //     // The request was made but no response was received
    //     console.error('Request Error:', error.request);
    //   } else {
    //     console.error('Error:', error.message);
    //   }
    // }
  }
  render() {
    const {user,password,password2} = this.state;
    console.log('state',this.state)
    return (
      <div>
        <div className="d-flex align-items-center auth px-0">
          <div className="row w-100 mx-0">
            <div className="col-lg-4 mx-auto">
              <div className="card text-left py-5 px-4 px-sm-5">
                <div className="brand-logo">
                  <img src={require("../../assets/images/cf-logo-white.png")} alt="logo" />
                </div>
                <h4></h4>
                <h6 className="font-weight-light">Password Recovery {user.username ? 'of '+user.username : ''}</h6>
                <Form className="pt-3">
                  <Form.Group className="d-flex search-field">
                    <Form.Control type="password" placeholder="Your new password" size="lg" name="password" onChange={this.handleInputChange} className="h-auto" />
                  </Form.Group>
                  <Form.Group className="d-flex search-field">
                    <Form.Control type="password" placeholder="Repeat the new password" size="lg" name="password2" onChange={this.handleInputChange} className="h-auto" />
                  </Form.Group>
                  <br></br>
                  <small>Minimum length is: 6 characters.</small>
                  <div className="mt-3">
                    <Link className={"btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn " + (user.username && password==password2 && password.length>=6 ? '' : 'btn-disabled')} onClick={(event)=>{event.preventDefault();this.doChangeUserPassword()}}>Save Password</Link>
                  </div>
                  <div className="my-2 d-flex justify-content-between align-items-center">
                    {/* <div className="form-check">
                      <label className="form-check-label text-muted">
                        <input type="checkbox" className="form-check-input"/>
                        <i className="input-helper"></i>
                        Keep me signed in
                      </label>
                    </div> */}
                    {/* <a href="!#" onClick={event => event.preventDefault()} className="auth-link text-muted">Forgot password?</a> */}
                  </div>
                  {/* <div className="mb-2">
                    <button type="button" className="btn btn-block btn-facebook auth-form-btn">
                      <i className="mdi mdi-facebook mr-2"></i>Connect using facebook
                    </button>
                  </div>
                  <div className="text-center mt-4 font-weight-light">
                    Don't have an account? <Link to="/user-pages/register" className="text-primary">Create</Link>
                  </div> */}
                </Form>
              </div>
            </div>
          </div>
        </div>  
      </div>
    )
  }
}

export default withParams(PasswordForgot)
