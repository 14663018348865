import React, { Component, useContext } from "react";
import { withRouter } from "react-router-dom";
import "./App.scss";
import AppRoutes from "./AppRoutes";
import Navbar from "./shared/Navbar";
import Sidebar from "./shared/Sidebar";
import Footer from "./shared/Footer";
import Login from "./user-pages/Login";
import User from './services/User';
import LoadingContext from './ToggleContext';

import { withTranslation } from "react-i18next";
import LinearProgress from '@mui/material/LinearProgress';
class App extends Component {
  state = {};
  static contextType = LoadingContext
  componentDidMount() {
    this.onRouteChanged();
    this.setState({loading:false})
  }
    
  render() {
    const { loading, setLoading_ } = this.context;
    let navbarComponent = !this.state.isFullPageLayout ? <Navbar /> : "";
    let sidebarComponent = !this.state.isFullPageLayout ? <Sidebar /> : "";
    let footerComponent = !this.state.isFullPageLayout ? <Footer /> : "";
    let user = User.get();
    console.log(this.props.location.pathname)
    return (user && user.jwt )|| (['/password/forgot'].includes(this.props.location.pathname) || this.props.location.pathname.includes('/password/reset/')) ? (
      <div className="container-scroller">
        {(!['/password/reset','/password/forgot'].includes(this.props.location.pathname) && !this.props.location.pathname.includes('/password/reset/') )&& sidebarComponent}
        <div className="container-fluid page-body-wrapper">
          {(!['/password/reset','/password/forgot'].includes(this.props.location.pathname) && !this.props.location.pathname.includes('/password/reset/')) && navbarComponent}
          <div className="main-panel">
            <div className="content-wrapper">
              <LinearProgress style={{display:loading?'block':'none'}}/>
              <AppRoutes/>
            </div>
            {footerComponent}
          </div>
        </div>
      </div>
    ) : (
      <div className="container-scroller">
        <div className="container-fluid page-body-wrapper">
        <div className="main-panel">
            <div className="content-wrapper">
              <Login/>  
            </div>
            {footerComponent}
          </div>
        </div>
      </div>
    );
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  onRouteChanged() {
    // const { i18n } = this.props;
    // const body = document.querySelector("body");
    // if (this.props.location.pathname === "/layout/RtlLayout") {
    //   body.classList.add("rtl");
    //   i18n.changeLanguage("ar");
    // } else {
    //   body.classList.remove("rtl");
    //   i18n.changeLanguage("en");
    // }
    window.scrollTo(0, 0);
    const fullPageLayoutRoutes = [
      "/login",
      "/password/reset",
      "/password/forgot",
      "/chooseAccount",
      "/user-pages/login-2",
      "/user-pages/register-1",
      "/user-pages/register-2",
      "/user-pages/lockscreen",
      "/error-pages/error-404",
      "/error-pages/error-500",
      "/general-pages/landing-page",
    ];
    if(null==document.querySelector(".page-body-wrapper")) return;
    for (let i = 0; i < fullPageLayoutRoutes.length; i++) {
      if (this.props.location.pathname === fullPageLayoutRoutes[i] || this.props.location.pathname.includes(fullPageLayoutRoutes[i])) {
        this.setState({
          isFullPageLayout: true,
        });
        document
          .querySelector(".page-body-wrapper")
          .classList.add("full-page-wrapper");
        break;
      } else {
        this.setState({
          isFullPageLayout: false,
        });
        document
          .querySelector(".page-body-wrapper")
          .classList.remove("full-page-wrapper");
      }
    }
  }
}

export default withTranslation()(withRouter(App));
