const cf_main_container =  `

    <div style="min-height:50px;height:auto;margin:0 auto 10px auto;padding:{{CONTAINER_PADDING}};width:100%;max-width:{{CONTAINER_WIDTH}};background-color:{{CONTAINER_BACKGROUND}}">
        <table data-gjs-highlightable="true" id="idv60" data-gjs-type="table" draggable="true" class="">
            <tbody data-gjs-highlightable="true" id="idv65" data-gjs-type="tbody" draggable="true" class="">
                <tr data-gjs-highlightable="true" id="ikj8g" data-gjs-type="row" draggable="true" class="">
                    <td data-gjs-highlightable="true" id="invtt" data-gjs-type="cell" draggable="true" class="">
                    </td>
                </tr>
            </tbody>
        </table>
    </div>`

export default cf_main_container